import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import sortableList from "@/components/common/SortableList";
import { downloadStudentsCSV } from "@/services/common";
import RowItem from "../RowItem";
import MessageModal from "../common/MessageModal.vue";
import { mapStores } from "pinia";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";
export default {
  name: "ClassRoomsIndex",
  components: {
    RowItem,
    LoadingMessage,
    sortableList,
    MessageModal
  },

  data() {
    return {
      selectedGrade: false,
      filteredGrades: [],
      showModal: false,
      classroomSortList: [],
      sortChangedItems: [],
      sortSaveComplete: false,
      isLoading: {
        sortData: true,
        classroomData: true
      },
      localModalSettings: {
        title: "",
        message: ""
      }
    };
  },

  computed: { ...mapState(["grades", "studentAddress"]),
    ...mapStores(useGradeClassroomStore)
  },

  created() {
    this.setData();
  },

  methods: {
    downloadStudentsCSV,

    setData() {
      getData(["grades?with=classroom", "reader/get_classroom_with_students_count"]).then(data => {
        this.$store.dispatch("commitGrade", data[0].items);
        this.gradeClassroomStore.grades = data[0].items; // pinia使用のため重複管理を許容する

        this.filteredGrades = data[0].items.filter(v => {
          return !v.fiscal_year;
        });
        data[1].items.forEach(v => {
          this.filteredGrades = this.filteredGrades.map(g => {
            g.classroom = g.classroom.map(c => {
              if (v.id === c.id) {
                c.studentCount = v.students_count;
              }

              return c;
            });
            g.classroom.sort((a, b) => {
              // 複式学級は末尾
              if (a.classroom_grade.length > 1 || b.classroom_grade.length > 1 || a.index > b.index) return 1;

              if (a.index < b.index) {
                return -1;
              }

              return 0;
            });
            return g;
          });
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.classroomData = false;
      });
    },

    gradeFilter: function () {
      let grades = this.filteredGrades = this.grades;
      let grade_id = this.selectedGrade;
      this.filteredGrades = grade_id ? grades.filter(v => v.id === grade_id) : grades;
    },
    showModalSort: function (grade_id) {
      this.sortChangedItems = [];
      this.sortSaveComplete = false;
      this.showModal = true;
      this.isLoading.sortData = true;
      getData("grades?with=classroom").then(data => {
        this.classroomSortList = data.items.filter(v => v.id === grade_id)[0].classroom;
        this.isLoading.sortData = false;
      }).catch(error => {
        console.log(error);
      });
    },
    saveClassroomOrder: function () {
      const classroomSortList = this.classroomSortList.map((v, i) => {
        v.index = i + 1;
        return v;
      });
      createData("writer/write_classroom_index", {
        classrooms: classroomSortList
      }).then(() => {
        this.showModal = false;
        this.setData();
        this.localModalSettings = {
          title: "保存完了",
          message: "クラスの並び替えを保存しました"
        };
        this.sortSaveComplete = true;
      }).catch(error => {
        this.localModalSettings = {
          title: "保存失敗",
          message: "クラスの並び替えに失敗しました"
        };
        console.log(error);
      });
    },
    cancelClassroomOrder: function () {
      this.showModal = false;
    },
    handleCancel: function () {
      this.showModal = false;
    },

    completeRefresh() {
      this.sortSaveComplete = false;
      this.$router.push({
        name: "classrooms"
      });
    }

  }
};