import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { mapState } from "vuex";
import MessageModal from "../common/MessageModal.vue";
import { getData, createData, updateData, deleteData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import Encoding from "encoding-japanese";
import RowItem from "../RowItem";
export default {
  name: "CreateGrade",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage
  },

  data() {
    return {
      showModal: false,
      grade: {},
      isLoading: {
        getData: false,
        postData: false
      },
      loadingMessage: "",
      error: {
        name: null
      }
    };
  },

  computed: { ...mapState(["grades"])
  },

  created() {
    if (this.$route.params.id) {
      this.setData();
    }
  },

  methods: {
    setData() {
      if (this.$route.params.id) {
        this.isLoading.getData = true;
        getData("grades/" + this.$route.params.id).then(data => {
          if (data) {
            this.grade = data.items[0];
          }
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.getData = false;
        });
      }
    },

    handleChange(e) {
      this.grade.name = Encoding.toHankakuCase(e.target.value).trim();
    },

    saveData() {
      let valid = true;
      this.error = {
        name: null
      };

      if (!this.grade.name) {
        this.error.name = "学年を入力してください。";
        valid = false;
      }

      if (this.grades.find(v => this.$route.params.id !== v.id && v.name === this.grade.name)) {
        this.error.name = "既存の学年と重複しています。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      this.grade.name = Encoding.toHankakuCase(this.grade.name).trim();

      if (this.$route.params.id) {
        this.loadingMessage = "学年データを更新しています。";
        this.isLoading.postData = true;
        updateData("writer/write_grade/" + this.$route.params.id, this.grade).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "学年更新が完了しました",
            title: "更新完了"
          });
          this.showModal = true;
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "更新に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading.postData = false;
        });
      } else {
        this.loadingMessage = "学年データを登録しています。";
        this.isLoading.postData = true;
        createData("writer/write_grade", this.grade).then(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "学年登録が完了しました",
            title: "登録完了"
          });
          this.showModal = true;
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "作成に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading.postData = false;
        });
      }
    },

    removeData() {
      this.loadingMessage = "学年データを削除しています。";
      this.isLoading.postData = true;
      deleteData("eraser/delete_grade/" + this.$route.params.id).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "学年を削除しました",
          title: "削除完了"
        });
      }).catch(error => {
        //APIレスポンスのエラーメッセージの構造に癖がある
        const message = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[1].join(",") : "";
        this.$store.dispatch("commitModalMessage", {
          message: "学年を削除できませんでした。" + message,
          title: "エラー"
        });
      }).then(() => {
        this.showModal = true;
        this.isLoading.postData = false;
      });
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "grades"
      });
    }

  }
};