import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import moment from "moment";
import MessageModal from "../common/MessageModal.vue";
import { getData, deleteData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import ConfirmModal from "@/components/common/ConfirmModal";
import { iconColor } from "@/consts/iconColor";
import { getAbsentAlert, assignAbsentBudge } from "@/services/common";
moment.locale("ja");
export default {
  name: "DetailAttendance",
  components: {
    MessageModal,
    LoadingMessage,
    ConfirmModal
  },

  data() {
    return {
      fiscalDate: moment().add(-3, "month").year() + "-04-01",
      showModal: false,
      student: {},
      attendances: [],
      isLoading: {
        getData: true,
        deleteData: false
      },
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      targetId: null,
      absentList3: [],
      absentList20: []
    };
  },

  created() {
    this.setData();
  },

  methods: {
    iconColor,
    getAttendance: function () {
      getData("attendances?student_id=" + this.$route.query.student_id + "&orderby=created_at&order=desc&show_daily_all=1").then(data => {
        const attendances = [];
        data.items.forEach(v => {
          let found = false;
          attendances.forEach(v2 => {
            if (v2.date === v.date) {
              if (v2["history"]) {
                v2["history"].push(v);
              } else {
                v2["history"] = [v];
              }

              found = true;
            }
          });

          if (!found) {
            v.showAction = moment(v.date) >= moment(this.fiscalDate);
            attendances.push(v);
          }

          v.isDeleted = !v.is_daily_latest;
        }); // 表示の整形

        data.items.forEach(v => {
          v.date = moment(v.date).format("YYYY年MM月DD日");
          v.created_at = moment(v.created_at).format("YYYY年MM月DD日 HH:mm");
        });
        this.attendances = attendances;
      }).catch(error => {
        console.log(error);
      });
    },

    async setData() {
      const a = await getAbsentAlert();
      const absentList3 = a.absentList3;
      const absentList20 = a.absentList20;
      const absentList30 = a.absentList30;
      const d = await getData("students/" + this.$route.query.student_id);
      const student = d.items[0];
      student.absents = assignAbsentBudge(student, absentList3, absentList20, absentList30);
      this.student = student;
      this.isLoading.getData = false;
      this.getAttendance();
    },

    closeModal() {
      this.showModal = false;
      this.getAttendance();
    },

    confirmDeleteData(id) {
      this.confirmMessageTitle = "出欠席情報削除";
      this.confirmMessage = "出欠席情報を削除します。よろしいですか？";
      this.targetId = id;
      this.showConfirmModal = true;
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    removeAttendance() {
      this.isLoading.deleteData = true;
      deleteData("attendances/" + this.targetId).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "出欠席情報が削除されました",
          title: "削除完了"
        });
        this.showModal = true;
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: "出欠席情報を削除できませんでした",
          title: "エラー"
        });
        this.showModal = true;
        console.log(error);
      }).then(() => {
        this.isLoading.deleteData = false;
        this.showConfirmModal = false;
      });
    }

  }
};