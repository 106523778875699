import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('section', {
    staticClass: "absent_list__wrapper"
  }, [_c('section', {
    staticClass: "absent_list"
  }, [_c('p', {
    staticClass: "absent_list__heading"
  }, [_vm._v(" 3日以上連続欠席 (" + _vm._s(_setup.absentList3.length) + "名) ")]), _c('div', {
    staticClass: "absent_list__content"
  }, [_c('dl', _vm._l(_setup.absentList3, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "absent_list__flex"
    }, [_vm._m(0, true), _c('dd', [_c('p', {
      staticClass: "reason"
    }, [_c('span', {
      staticClass: "icon--orange"
    }, [_vm._v(_vm._s(value.attendance_today))])]), _c('div', {
      staticClass: "name_wrapper"
    }, [_c('p', {
      staticClass: "class"
    }, [_vm._v(" " + _vm._s(value.classroom_name) + " ")]), _c('p', {
      staticClass: "name"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.detail',
          query: {
            student_id: value.student_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(value.name) + " ")]), _setup.latestDekitusByStudent.find(v => v.student_id === value.student_id) ? _c('span', [_c('i', {
      staticClass: "fas fa-laptop"
    }), _vm._v(" " + _vm._s(_setup.moment(_setup.latestDekitusByStudent.find(v => v.student_id === value.student_id).start_date).format("MM月DD日")) + " ")]) : _vm._e()], 1)])])]);
  }), 0)])]), _c('section', {
    staticClass: "absent_list"
  }, [_c('p', {
    staticClass: "absent_list__heading"
  }, [_vm._v(" 累積20日以上欠席 (" + _vm._s(_setup.absentList20.length) + "名) ")]), _c('div', {
    staticClass: "absent_list__content"
  }, [_c('dl', _vm._l(_setup.absentList20, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "absent_list__flex"
    }, [_c('dt', [_c('p', {
      staticClass: "icon--date"
    }, [_c('span', {
      staticClass: "num"
    }, [_vm._v(_vm._s(value.absence_count))]), _vm._v("欠 ")])]), _c('dd', [value.attendance_today ? _c('p', {
      staticClass: "reason"
    }, [_c('span', {
      staticClass: "icon--orange"
    }, [_vm._v(_vm._s(value.attendance_today))])]) : _vm._e(), _c('div', {
      staticClass: "name_wrapper"
    }, [_c('p', {
      staticClass: "class"
    }, [_vm._v(" " + _vm._s(value.classroom_name) + " ")]), _c('p', {
      staticClass: "name"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.detail',
          query: {
            student_id: value.student_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(value.name) + " ")]), _setup.latestDekitusByStudent.find(v => v.student_id === value.student_id) ? _c('span', [_c('i', {
      staticClass: "fas fa-laptop"
    }), _vm._v(" " + _vm._s(_setup.moment(_setup.latestDekitusByStudent.find(v => v.student_id === value.student_id).start_date).format("MM月DD日")) + " ")]) : _vm._e()], 1)])])]);
  }), 0)])]), _c('section', {
    staticClass: "absent_list"
  }, [_c('p', {
    staticClass: "absent_list__heading"
  }, [_vm._v(" 累積30日以上欠席 (" + _vm._s(_setup.absentList30.length) + "名) ")]), _c('div', {
    staticClass: "absent_list__content"
  }, [_c('dl', _vm._l(_setup.absentList30, function (value, index) {
    return _c('div', {
      key: index,
      staticClass: "absent_list__flex"
    }, [_c('dt', [_c('p', {
      staticClass: "icon--date"
    }, [_c('span', {
      staticClass: "num"
    }, [_vm._v(_vm._s(value.absence_count))]), _vm._v("欠 ")])]), _c('dd', [value.attendance_today ? _c('p', {
      staticClass: "reason"
    }, [_c('span', {
      staticClass: "icon--orange"
    }, [_vm._v(_vm._s(value.attendance_today))])]) : _vm._e(), _c('div', {
      staticClass: "name_wrapper"
    }, [_c('p', {
      staticClass: "class"
    }, [_vm._v(" " + _vm._s(value.classroom_name) + " ")]), _c('p', {
      staticClass: "name"
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'attendances.detail',
          query: {
            student_id: value.student_id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(value.name) + " ")]), _setup.latestDekitusByStudent.find(v => v.student_id === value.student_id) ? _c('span', [_c('i', {
      staticClass: "fas fa-laptop"
    }), _vm._v(" " + _vm._s(_setup.moment(_setup.latestDekitusByStudent.find(v => v.student_id === value.student_id).start_date).format("MM月DD日")) + " ")]) : _vm._e()], 1)])])]);
  }), 0)])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('dt', [_c('p', {
    staticClass: "icon--date"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v("3")]), _vm._v("欠")])]);
}];
export { render, staticRenderFns };