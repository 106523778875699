import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
import PageSwitch from "./PageSwitch";
export default {
  name: "Status",
  components: {
    RowItem,
    PageSwitch
  },

  data() {
    return {
      to: "students",
      enquete: {},
      isLoading: {
        enquete: true,
        statuses: true
      },
      reSendTargets: [],
      checkAllFlag: false,
      filterOptions: {
        status: false,
        keyword: ""
      },
      showModal: false,
      title: "",
      statuses: [],
      studentStatuses: [],
      participantStatuses: [],
      filteredStatuses: [],
      modalStatus: {}
    };
  },

  computed: { ...mapState(["classrooms", "studentAddress"]),
    answeredCnt: function () {
      return this.statuses.filter(v => v.answer.length > 0).length;
    },
    sendTargetsParam: function () {
      return this.to === "students" ? {
        students: this.reSendTargets,
        participants: []
      } : {
        students: [],
        participants: this.reSendTargets
      };
    }
  },
  watch: {
    to() {
      this.filterList();
    }

  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("enquetes/progress/" + this.$route.params.id).then(data => {
        this.enquete = data.items;
      }).catch(error => {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response && this.$route.name !== "enquetes") {
          this.$router.push({
            name: "enquetes"
          });
        }
      }).then(() => {
        this.isLoading.enquete = false;
      });
      getData("reader/get_enquete_statuses/" + this.$route.params.id).then(data => {
        if (data) {
          this.statuses = this.studentStatuses = this.filteredStatuses = data.items.students.map(v => {
            const firstGuardianId = v.guardian[0] ? v.guardian[0].id : null;
            v.type = "email";

            if (firstGuardianId) {
              const messageStatus = v.guardian_student.find(v2 => v2.id === firstGuardianId);

              if (messageStatus) {
                v.type = messageStatus.type;
              }
            }

            return v;
          });
          this.participantStatuses = data.items.participants.map(v => {
            v.type = v.participant_message_status && v.participant_message_status[0] ? v.participant_message_status[0].type : "email";
            return v;
          });
        }
      }).catch(error => {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る
        // if (error.response && this.$route.name !== "enquetes") {
        //   this.$router.push({ name: "enquetes" });
        // }
      }).then(() => {
        this.isLoading.statuses = false;
      });
    },

    showDetail(target_id) {
      const target = this.to === "students" ? "student" : "participant";
      getData("reader/get_enquete_answers_by_target?enquete_id=" + this.$route.params.id + "&target_id=" + target_id + "&target=" + target).then(data => {
        if (data) {
          const v = data.items;
          v.questions = v.questions.map(v2 => {
            v2.subject = "";
            v2.options = [];

            if (v2.question_contents_i18n && v2.question_contents_i18n[0]) {
              const questionContents = v2.question_contents_i18n[0];
              v2.subject = questionContents.subject;

              if (questionContents.options) {
                v2.options = questionContents.options;
              }

              v2.subject = questionContents.subject;

              if (target === "participant") {
                v2.answer = v2.participant_answer;
              }
            }

            return v2;
          });
          this.modalStatus = v;
        }
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.showModal = true;
      });
    },

    handleReSendButton() {
      if (this.enquete.message_id) {
        getData("reader/get_message_detail/" + this.enquete.message_id).then(data => {
          const message_detail = data.items;
          this.$router.push({
            name: "messages.create",
            params: {
              targets: this.sendTargetsParam,
              content: {
                subject: message_detail.content.subject,
                call_body: message_detail.content.call_body,
                mail_body: message_detail.content.mail_body,
                attached_file: message_detail.message.attached_file
              },
              enquete_subject: message_detail.enquete_contents && message_detail.enquete_contents.subject ? message_detail.enquete_contents.subject : "",
              questions: message_detail.questions
            }
          });
        }).catch(error => {
          console.log(error);
        });
      }
    },

    getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },

    getAnsweredTime(answer) {
      const created_at = answer.created_at;
      return moment(created_at).isValid() ? moment(created_at).format("llll") : "";
    },

    getAnswer(answer, question) {
      if (!answer.body) return "（未回答）";
      return question.type === "single" ? question.options[answer.body] : answer.body;
    },

    checkAll() {
      if (this.checkAllFlag) {
        if (this.to === "students") {
          this.reSendTargets = this.filteredStatuses.map(v => v.id);
        } else {
          this.reSendTargets = this.filteredStatuses.map(v => v.participant.id);
        }
      } else {
        this.reSendTargets = [];
      }
    },

    // すべてチェック用チェックボックスの監視
    testAllChecked() {
      this.checkAllFlag = this.statuses.map(v => v.id).length === this.reSendTargets.length;
    },

    filterList() {
      let statuses = this.statuses = this.to === "students" ? this.studentStatuses : this.participantStatuses;
      let status = this.filterOptions.status;
      let keyword = this.filterOptions.keyword;

      if (status) {
        statuses = statuses.filter(v => {
          const flag = v.answer.length > 0;
          return status === "answered" ? flag : !flag;
        });
      }

      if (keyword !== "") {
        if (this.to === "students") {
          statuses = statuses.filter(v => {
            return v.name.indexOf(keyword) > -1 || v.ruby.indexOf(keyword) > -1 || v.guardian.findIndex(v2 => v2.name.indexOf(keyword) > -1) > -1 || v.guardian.findIndex(v2 => v2.ruby.indexOf(keyword) > -1) > -1;
          });
        } else {
          statuses = statuses.filter(v => v.name.indexOf(keyword) > -1 || v.belonging && v.belonging.indexOf(keyword) > -1);
        }
      }

      this.filteredStatuses = statuses;
    },

    handleTo(val) {
      this.to = val;
    }

  }
};