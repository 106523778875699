import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { createData, deleteData, getData, updateData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import MessageModal from "@/components/common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
import passwordValidator from "password-validator";
import { mapState } from "vuex";
const schema = new passwordValidator();
schema.is().min(8).is().max(100).has().uppercase().has().lowercase().has().digits().has().not().spaces();
export default {
  name: "EditTeacher",
  components: {
    RowItem,
    LoadingMessage,
    MessageModal,
    ConfirmModal
  },
  data: () => ({
    showModal: false,
    isLoading: {
      classroom: true,
      teacher: true,
      classTeacher: true,
      postTeacher: false
    },
    loadingMessage: "",
    gradeClass: [],
    classrooms: [],
    teacher: {
      name: "",
      email: "",
      role_id: 12,
      use_mfa: false,
      account_lock: false
    },
    notifyRegistered: true,
    pageTitle: "先生登録",
    buttonLabel: "登録する",
    buttonDisabled: false,
    error: {
      name: null,
      email: null,
      classrooms: null
    },
    showConfirmModal: false,
    deleteActionType: "delete",
    confirmMessageTitle: "",
    confirmMessage: "",
    role_radio_options: [{
      id: 12,
      label: "一般教職員権限"
    }, {
      id: 11,
      label: "管理者権限"
    }]
  }),
  head: {
    title: function () {
      return {
        inner: this.pageTitle
      };
    }
  },
  computed: { ...mapState(["me"])
  },

  created() {
    if (this.$route.params.id) {
      this.pageTitle = "先生編集";
      this.buttonLabel = "更新する";
    }

    this.setData();
  },

  methods: {
    setData() {
      getData("grades?with=classroom").then(data => {
        this.gradeClass = data.items;
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.classroom = false;
      });

      if (this.$route.params.id) {
        getData("reader/get_teachers_with_classrooms/" + this.$route.params.id).then(data => {
          this.teacher = data.items[0];
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.teacher = false;
        });
        getData("teachers/classroom_teacher/" + this.$route.params.id).then(data => {
          data.items.forEach(e => {
            this.classrooms.push(e.classroom_id);
          });
        }).catch(error => {
          console.log(error);
        }).then(() => {
          this.isLoading.classTeacher = false;
        });
      } else {
        this.isLoading.teacher = false;
        this.isLoading.classTeacher = false;
      }
    },

    saveData() {
      this.buttonDisabled = true; //        this.buttonLabel = 'おまちください'

      let valid = true;
      this.error = {
        name: null,
        email: null,
        classrooms: null
      };

      if (!this.teacher.name) {
        this.error.name = "名前を入力してください。";
        valid = false;
      } else {
        this.teacher.name = this.teacher.name.replace(/\s+/, " ");
      }

      if (!this.teacher.email) {
        this.error.email = "メールアドレスを入力してください。";
        valid = false;
      } else if (!emailValidator.validate(this.teacher.email)) {
        this.error.email = "メールアドレスの形式が正しくありません。";
        valid = false;
      }

      if (!valid) {
        this.buttonDisabled = false;
        return;
      }

      let payload = Object.assign(this.teacher, {
        classrooms: this.classrooms,
        notifyRegistered: !this.$route.params.id && this.notifyRegistered
      });
      this.isLoading.postTeacher = true;

      if (this.$route.params.id) {
        updateData("writer/write_teacher/" + this.$route.params.id, payload).then(result => {
          if (result.error_code) {
            switch (result.error_code) {
              case 404:
                this.$store.dispatch("commitModalMessage", {
                  message: "先生の更新に失敗しました (Auth0 user not found)",
                  title: "エラー"
                });
                break;

              default:
                this.$store.dispatch("commitModalMessage", {
                  message: "先生の更新に失敗しました (" + result.errors + ")",
                  title: "エラー"
                });
                break;
            }
          } else {
            this.$store.dispatch("commitModalMessage", {
              message: "先生更新が完了しました",
              title: "更新完了"
            });
          }

          console.log(result);
          return getData("teachers/whoami");
        }).then(data => {
          this.$store.dispatch("commitSelfProfile", data.items[0]);
        }).catch(error => {
          this.$store.dispatch("commitModalMessage", {
            message: "先生の更新に失敗しました",
            title: "エラー"
          });
          console.log(error);
        }).then(() => {
          this.isLoading.postTeacher = false;
          this.showModal = true;
          this.buttonDisabled = false;
        });
      } else {
        createData("writer/write_teacher", payload).then(result => {
          if (result.error_code === 409) {
            this.error.email = "このメールアドレスは既に使用されています";
            valid = false;
          } else if (result.code === "200") {
            this.$store.dispatch("commitModalMessage", {
              message: this.teacher.name + "先生は他校から異動してきました。以前の学校のパスワードでログインできます。",
              title: "異動完了"
            });
            this.showModal = true;
          } else {
            this.$store.dispatch("commitModalMessage", {
              message: "先生登録が完了しました",
              title: "登録完了"
            });
            this.showModal = true;
          }
        }).catch(error => {
          this.$store.dispatch("commitModalMessage", {
            message: "先生の更新に失敗しました",
            title: "エラー"
          });
          this.showModal = true;
          console.log(error);
        }).then(() => {
          this.isLoading.postTeacher = false;
          this.buttonDisabled = false;
        });
      }
    },

    confirmDelete() {
      this.confirmMessageTitle = "先生情報削除";
      this.confirmMessage = this.teacher.name + "先生を削除します。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
      this.deleteActionType = "delete";
    },

    confirmTransfer() {
      this.confirmMessageTitle = "先生異動";
      this.confirmMessage = this.teacher.name + "先生を異動します。他校へ異動した先生はこの学校にログインできなくなり、\n" + "新しい学校で再登録できるようになります。この先生の作成したメッセージやアンケートの作者は、\n" + "今操作をしている先生の名前に引き継がれます。本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
      this.deleteActionType = "transfer";
    },

    handleDeleteData() {
      this.showConfirmModal = false;
      this.buttonDisabled = true;
      let actionText = "削除";
      let params = "";

      if (this.deleteActionType === "transfer") {
        actionText = "異動";
        params = "?transfer=1";
      }

      deleteData("teachers/" + this.$route.params.id + params).then(result => {
        if (result.error_code) {
          switch (result.error_code) {
            case 404:
              this.$store.dispatch("commitModalMessage", {
                message: "先生の" + actionText + "に失敗しました (Auth0 user not found)",
                title: "エラー"
              });
              break;

            default:
              this.$store.dispatch("commitModalMessage", {
                message: "先生の" + actionText + "に失敗しました (" + result.errors + ")",
                title: "エラー"
              });
              break;
          }
        } else {
          this.$store.dispatch("commitModalMessage", {
            message: "先生データを" + actionText + "しました",
            title: actionText + "完了"
          });
        }
      }).catch(error => {
        this.$store.dispatch("commitModalMessage", {
          message: "先生データの" + actionText + "に失敗しました",
          title: "エラー"
        });
        console.log(error);
      }).then(() => {
        this.showModal = true;
        this.buttonDisabled = false;
      });
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "teachers"
      });
    }

  }
};