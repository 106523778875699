import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import Vue from "vue";
import RowItem from "../RowItem";
import MessageModal from "../common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
import { getData, createData, updateData, deleteData } from "@/services/axios";
import moment from "moment";
export default {
  name: "Edit",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage,
    ConfirmModal
  },
  data: () => ({
    id: null,
    showModal: false,
    showConfirmModal: false,
    confirmMessageTitle: "",
    confirmMessage: "",
    modalAction: "save",
    isLoading: false,
    error: {
      title: "",
      from: "",
      to: "",
      eventClassroom: ""
    },
    allCheck: false,
    isAllDay: true,
    title: "",
    from: null,
    to: null,
    isSchoolDayOff: 0,
    eventClassroom: [],
    selectedGrades: [],
    sendMessage: false
  }),
  computed: { ...mapState(["grades", "classrooms", "me"]),
    filteredClassrooms: function () {
      const classrooms = [];
      this.grades.forEach(v => {
        if (this.selectedGrades.includes(v.id)) {
          v.classroom.forEach(v2 => {
            if (!classrooms.find(v3 => v3.id === v2.id)) {
              classrooms.push(v2);
            }
          });
        }
      });
      return classrooms;
    }
  },
  watch: {
    from(val) {
      if (val && (!this.to || this.from > this.to)) {
        this.to = moment(this.from).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
      }
    },

    allCheck(val) {
      if (val) {
        this.eventClassroom = this.classrooms.map(v => v.id);
        this.selectedGrades = this.grades.map(v => v.id);
      }
    },

    eventClassroom(val) {
      this.allCheck = val.length === this.classrooms.length;
    },

    selectedGrades(currentGrades, previousGrades) {
      if (currentGrades.length > previousGrades.length) {
        // 新しく追加された学年を見つける
        const newGrade = currentGrades.find(v => {
          return !previousGrades.includes(v);
        });
        const grade = this.grades.find(v => v.id === newGrade);

        if (grade) {
          const gradeClassroom = grade.classroom;
          const gradeClassroomIds = gradeClassroom.map(v => v.id);
          this.eventClassroom = this.eventClassroom.concat(gradeClassroomIds).filter((elem, index, self) => {
            return self.indexOf(elem) === index;
          });
        }
      }
    },

    filteredClassrooms(val, oldVal) {
      if (val.length < oldVal.length) {
        this.eventClassroom = this.eventClassroom.filter(v => val.find(v2 => v2.id === v));
      }
    }

  },

  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      getData("events/" + this.$route.params.id + "?with=classroom").then(data => {
        const v = data.items[0];
        this.title = v.title;
        this.from = v.from;
        this.to = v.to;
        this.isSchoolDayOff = v.is_school_day_off;
        this.isAllDay = v.is_all_day;
        this.eventClassroom = v.classroom.map(v => v.classroom_id);
        const selectedGrades = [];
        this.grades.forEach(v2 => {
          if (v2.classroom.find(v3 => this.eventClassroom.includes(v3.id))) {
            selectedGrades.push(v2.id);
          }
        });
        this.selectedGrades = selectedGrades;
        Vue.nextTick().then(() => {
          // selectedGradesのwatchを待ってから正しいクラスで上書き
          this.eventClassroom = v.classroom.map(v => v.classroom_id);
        });
      });
    }
  },

  methods: {
    validate: function () {
      let valid = true;
      this.error = {
        title: "",
        from: "",
        to: "",
        eventClassroom: ""
      };

      if (!this.from) {
        this.error.from = "開始日時を入力してください。";
        valid = false;
      }

      if (!this.to) {
        this.error.to = "終了日時を入力してください。";
        valid = false;
      }

      if (!this.title) {
        this.error.title = "タイトルを入力してください。";
        valid = false;
      }

      if (this.title && this.title.length > 200) {
        this.error.title = "タイトルは200文字以内で入力してください";
        valid = false;
      }

      if (this.eventClassroom.length === 0) {
        this.error.eventClassroom = "対象クラスを入力してください。";
        valid = false;
      }

      return valid;
    },
    saveEvent: function () {
      const valid = this.validate();

      if (!valid) {
        return;
      }

      this.isLoading = true;

      if (this.$route.params.id) {
        this.loadingMessage = "年間予定を更新しています。";
        updateData("writer/write_event/" + this.$route.params.id, {
          title: this.title,
          from: this.from,
          to: this.to,
          is_school_day_off: this.isSchoolDayOff,
          is_all_day: this.isAllDay,
          event_classroom: this.eventClassroom
        }).then(data => {
          this.id = data.id;
          this.$store.dispatch("commitModalMessage", {
            message: "年間予定更新が完了しました",
            title: "更新完了"
          });
          this.showModal = true;
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "年間予定更新に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading = false;
        });
      } else {
        this.loadingMessage = "年間予定を登録しています。";
        createData("writer/write_event", {
          title: this.title,
          from: this.from,
          to: this.to,
          is_school_day_off: this.isSchoolDayOff,
          is_all_day: this.isAllDay,
          event_classroom: this.eventClassroom
        }).then(data => {
          this.id = data.id;
          this.$store.dispatch("commitModalMessage", {
            message: "年間予定登録が完了しました",
            title: "登録完了"
          });
          this.showModal = true;
        }).catch(() => {
          this.$store.dispatch("commitModalMessage", {
            message: "年間予定登録に失敗しました。",
            title: "エラー"
          });
          this.showModal = true;
        }).then(() => {
          this.showModal = true;
          this.isLoading = false;
        });
      }
    },

    confirmCreate() {
      const valid = this.validate();

      if (valid) {
        this.modalAction = "save";
        this.confirmMessageTitle = "イベント登録";
        this.confirmMessage = moment(this.from).format("YYYY年MM月DD日(ddd)") + (moment(this.from).startOf("day").isSame(moment(this.to).startOf("day")) ? "" : "から" + moment(this.to).format("YYYY年MM月DD日(ddd)")) + "は" + (this.isSchoolDayOff ? "休日" : "授業日") + "となります。\nよろしいですか？";
        this.showConfirmModal = true;
      }
    },

    confirmDelete() {
      this.modalAction = "delete";
      this.confirmMessageTitle = "イベント削除";
      this.confirmMessage = "イベントを削除します。\n本当によろしいですか？";
      this.showConfirmModal = true;
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    handleModalAction() {
      if (this.modalAction === "delete") {
        this.deleteData();
      } else {
        this.saveEvent();
      }

      this.showConfirmModal = false;
    },

    deleteData() {
      this.isLoading = true;
      this.showConfirmModal = false;
      deleteData("eraser/delete_event/" + this.$route.params.id).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "イベント削除が完了しました",
          title: "削除完了"
        });
      }).catch(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "イベント削除に失敗しました",
          title: "削除失敗"
        });
      }).then(() => {
        this.showModal = true;
      });
    },

    completeAction() {
      this.showModal = false;

      if (this.sendMessage) {
        this.isLoading = true;
        let formattedDate = "";

        if (this.isAllDay) {
          formattedDate = moment(this.from).format("MM月DD日(ddd)");

          if (moment(this.from).format("YYYY-MM-DD") !== moment(this.to).format("YYYY-MM-DD")) {
            formattedDate += " - " + moment(this.to).format("MM月DD日(ddd)");
          }
        } else {
          formattedDate = moment(this.from).format("MM月DD日(ddd) HH:mm");

          if (moment(this.from).format("YYYY-MM-DD") === moment(this.to).format("YYYY-MM-DD")) {
            formattedDate += " - " + moment(this.to).format("HH:mm");
          } else {
            formattedDate += " - " + moment(this.to).format("MM月DD日(ddd) HH:mm");
          }
        }

        const subject = formattedDate + " " + this.title;
        let body = `${this.me.name}先生から予定が登録されました。
${subject}

COCOO保護者サイトからカレンダーを確認してください。
`;
        let studentQuery = "";
        this.eventClassroom.forEach(v => {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
        getData("reader/get_student_list_for_messenger" + studentQuery).then(data => {
          this.isLoading = false;

          if (data.items) {
            this.$router.push({
              name: "messages.create",
              params: {
                targets: {
                  students: data.items.map(v => v.id),
                  participants: []
                },
                content: {
                  subject,
                  call_body: body,
                  mail_body: body
                }
              }
            });
          }
        });
      } else {
        this.$router.push({
          name: "events"
        });
      }
    },

    handleAllCheck() {
      if (!this.allCheck) {
        this.eventClassroom = [];
        this.selectedGrades = [];
      }
    }

  }
};