import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import { mapState } from "vuex";
import { getData, getDataByPostMethod } from "@/services/axios";
import ConfirmModal from "@/components/common/ConfirmModal";
import RowItem from "../RowItem";
import moment from "moment";
export default {
  name: "StudentsIndex",
  components: {
    RowItem,
    ConfirmModal
  },
  data: () => ({
    isLoading: true,
    selectedClass: false,
    showModal: false,
    students: [],
    filteredStudents: [],
    guardiansLimit: process.env.VUE_APP_GUARDIANS_LIMIT,
    filterParam: {
      keyword: "",
      classroom: false,
      hasGuardian: false
    },
    showConfirmModal: false,
    confirmMessageTitle: "",
    confirmMessage: "",
    targetStudentIds: [],
    numberOfStudents: 0,
    hasGuardian: 0,
    registrationRate: 0
  }),
  head: {
    title: () => ({
      inner: "全校一覧"
    }),
    meta: []
  },
  computed: { ...mapState(["grade", "classrooms", "studentAddress", "me"])
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("students?with[]=guardian&with[]=classroom").then(data => {
        this.students = this.filteredStudents = data.items;
        this.calcRegistrationRate(this.filteredStudents);
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    filterList() {
      let list = this.students;
      let keyword = this.filterParam.keyword;
      let class_id = this.filterParam.classroom;
      let hasGuardian = this.filterParam.hasGuardian;

      if (keyword) {
        keyword = keyword.trim().split(/[\x20\u3000]+/);
        list = list.filter(v => Object.keys(v).some(k => keyword.find(kw => String(v[k]).indexOf(kw) > -1)));
      }

      if (class_id) {
        list = list.filter(v => v.class_id === class_id);
      }

      if (hasGuardian) {
        list = list.filter(v => !(v.guardian && v.guardian.length > 0));
      }

      this.filteredStudents = list;
      this.calcRegistrationRate(this.filteredStudents);
    },

    resetFilterParam() {
      this.filterParam.keyword = "";
      this.filterParam.classroom = false;
      this.filterParam.hasGuardian = false;
      this.filteredStudents = this.students;
      this.calcRegistrationRate(this.filteredStudents);
    },

    calcRegistrationRate(students) {
      this.hasGuardian = 0;
      this.numberOfStudents = students.length;
      students.forEach(v => {
        if (v.guardian && v.guardian.length > 0) {
          this.hasGuardian++;
        }
      });
      this.registrationRate = Math.round(this.hasGuardian / this.numberOfStudents * 100);
    },

    confirmGetQrCode() {
      this.targetStudentIds = this.filteredStudents.map(v => v.id);
      this.isLoading = true;
      getDataByPostMethod("reader/get_students_regenerated_qrcode", {
        student_id: this.targetStudentIds
      }).then(data => {
        this.isLoading = false;
        this.confirmMessageTitle = "QRコード表示（全生徒）";
        this.showConfirmModal = true;

        if (data.items.length > 0) {
          const nameSamples = data.items.slice(0, 3).reduce((p, v) => p + v.name + "さん" + "、", "");
          const othersCount = data.items.length > 3 ? "ほか" + (data.items.length - 3) + "名" : "";
          this.confirmMessage = "QRコードを一度発行している児童生徒が" + data.items.length + "名含まれています。\n" + nameSamples + othersCount + "\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
        } else {
          this.confirmMessage = this.targetStudentIds.length + "人分のQRコードを発行します。\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
        }
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
      });
    },

    confirmGetQrCodeSingle(student) {
      this.targetStudentIds = [student.id];
      this.confirmMessageTitle = "QRコード表示";
      this.confirmMessage = student.name + "さんのQRコードを発行します。\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
      this.showConfirmModal = true;
    },

    getQrCode() {
      this.showConfirmModal = false;
      this.$router.push({
        name: "students.qr_generate",
        params: {
          student_id: this.targetStudentIds
        }
      });
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    }

  }
};