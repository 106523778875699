import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { computed } from "vue";
import { useStore } from "vue2-helpers/vuex";
export default {
  __name: 'MonthlyTableHead',
  props: {
    mode: {
      type: String,
      default: "attendance"
    },
    monthData: {
      /** @type {PropType<MonthData[]>} */
      type: Array,
      required: true
    },
    showSubHead: {
      type: Boolean,
      default: false
    }
  },

  setup(__props) {
    const props = __props; // @ts-check

    const store = useStore();
    const studentAddress = computed(() => store.state.studentAddress);
    const schoolDayLength = computed(() => props.monthData.filter(v => !v.isSchoolDayOff).length);
    return {
      __sfc: true,
      store,
      props,
      studentAddress,
      schoolDayLength
    };
  }

};