import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_teachers_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("先生登録 ファイルをアップロードする")]), _vm.isLoading ? _c('loading-message', {
    attrs: {
      "loading-message": '登録中'
    }
  }) : _vm._e(), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('div', {
    staticClass: "csv_file_upload"
  }, [_c('div', {
    staticClass: "csv_file_upload__section"
  }, [_vm.csv.length === 0 ? _c('h2', {
    staticClass: "csv_file_upload__comment"
  }, [_vm._v(" 作成した名簿のファイルを選択してください。 ")]) : _vm._e(), _vm.error.length > 0 ? _c('h3', {
    staticClass: "text_center_border"
  }, [_vm._v(" CSVファイルの内容を修正し、もう一度ファイルをアップロードしてください。 ")]) : _vm._e(), _vm.error.length > 0 ? _c('div', {
    staticClass: "csv_file_upload__section"
  }, [_c('ul', _vm._l(_vm.error, function (e, i) {
    return _c('li', {
      key: i,
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(e) + " ")]);
  }), 0)]) : _vm._e(), _vm.csv.length > 0 && _vm.error.length > 0 ? _c('h3', {
    staticClass: "text_center_border"
  }, [_vm._v(" 修正したCSVファイルをアップロードする ")]) : _vm._e(), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    attrs: {
      "id": "file",
      "type": "file",
      "value": "参照"
    },
    on: {
      "change": _vm.setAttachment
    }
  }), _vm.csv.length === 0 || _vm.csv.length > 0 && _vm.error.length > 0 ? _c('label', {
    staticClass: "select_button",
    attrs: {
      "for": "file"
    }
  }, [_vm._v("CSVファイルを選択")]) : _vm._e()]), _vm.csv.length === 0 ? _c('div', {
    staticClass: "csv_file_upload__note"
  }, [_c('h2', [_vm._v("【先生名簿をこれから作成する場合】")]), !_vm.section1 ? _c('section', {
    on: {
      "click": function ($event) {
        return _vm.open('section1');
      }
    }
  }, [_c('pre', {
    staticClass: "hover-blue"
  }, [_vm._v("[ ▶ 説明を見る… ]")])]) : _vm._e(), _vm.section1 ? _c('section', [_c('p', [_vm._v(" 1.下記のボタンから先生名簿を入力するフォーマットファイルをダウンロードしてください。 ")])]) : _vm._e()]) : _vm._e(), _vm.section1 ? _c('section', [_c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('button', {
    staticClass: "download_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.downloadTemplateCSV.apply(null, arguments);
      }
    }
  }, [_vm._v(" 入力フォーマットダウンロード ")])]), _c('pre', {
    staticClass: "hover-blue",
    on: {
      "click": function ($event) {
        return _vm.close('section1');
      }
    }
  }, [_vm._v("[ ▲ 説明を閉じる ]")])]) : _vm._e()]), _vm.csv.length === 0 ? _c('div', {
    staticClass: "csv_file_upload__section"
  }, [_c('div', {
    staticClass: "csv_file_upload__note"
  }, [_c('h2', [_vm._v("【すでにExcelの先生名簿がある場合】")]), !_vm.section2 ? _c('section', {
    on: {
      "click": function ($event) {
        return _vm.open('section2');
      }
    }
  }, [_c('pre', {
    staticClass: "hover-blue"
  }, [_vm._v("[ ▶ 説明を見る… ]")])]) : _vm._e(), _vm.section2 ? _c('section', [_c('h3', [_vm._v(" 1.Excelの先生名簿を開き、名簿の列を下記のように並べ替えてください。 ")]), _vm._v(" 足りない列は新しく記入し、余計な列は削除してください。"), _c('br'), _vm._v(" 「氏名」「メールアドレス」「権限」「学年組」"), _c('br')]) : _vm._e(), _vm.section2 ? _c('section', [_c('h3', [_vm._v(" 2.編集したファイルを「名前をつけて保存」し、ファイル形式は「CSV（コンマ区切り）（.csv）」を選択してください。 ")])]) : _vm._e(), _vm.section2 ? _c('section', [_c('h3', [_vm._v(" 3.「CSVファイルを選択」をクリックして先生名簿を入力したファイルを選び、アップロードしてください。 ")])]) : _vm._e(), _vm.section2 ? _c('section', [_c('h3', [_vm._v(" 4.ファイルの内容を画面上でよく確認してから登録してください。 ")]), _vm._v(" 一括登録処理はやり直すことができません。"), _c('br'), _vm._v(" もし間違って登録してしまった場合は「先生管理」画面から一人ずつ修正・削除する必要があります。"), _c('br'), _vm._v(" 慎重にデータを確認してから登録してください。 "), _c('pre', {
    staticClass: "hover-blue",
    on: {
      "click": function ($event) {
        return _vm.close('section2');
      }
    }
  }, [_vm._v("[ ▲ 説明を閉じる ]")])]) : _vm._e(), _vm._v(" 【入力の注意】"), _c('br'), !_vm.section3 ? _c('section', {
    on: {
      "click": function ($event) {
        return _vm.open('section3');
      }
    }
  }, [_c('pre', {
    staticClass: "hover-blue"
  }, [_vm._v("[ ▶ 説明を見る… ]")])]) : _vm._e(), _vm.section3 ? _c('section', [_vm._v(" \"氏名\"は必須です。姓と名の間にはスペースを含めてください。"), _c('br'), _vm._v(" \"メールアドレス\"は必須かつ他人との重複ができません。受信できるメールアドレスにしてください。"), _c('br'), _vm._v(" \"権限\"は一般、管理者、あるいは空欄としてください。"), _c('br'), _vm._v(" \"学年組\"はCOCOOに登録されている学年組（クラス名）を入力してください。"), _c('br'), _vm._v(" 1クラスのみ設定可能です。設定しない場合は空欄としてください。 "), _c('pre', {
    staticClass: "hover-blue",
    on: {
      "click": function ($event) {
        return _vm.close('section3');
      }
    }
  }, [_vm._v("[ ▲ 説明を閉じる ]")])]) : _vm._e()])]) : _vm._e(), _vm.csv.length > 0 && _vm.error.length === 0 ? _c('div', {
    staticClass: "csv_file_upload__alert"
  }, [_vm._v(" 一括登録処理はやり直すことができません。慎重にデータを確認してから実行してください。 ")]) : _vm._e(), _c('section', {
    staticClass: "common_form__button_wrap page_information_create__bottom"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'teachers'
      }
    }
  }, [_vm._v(" キャンセル ")]), _vm.entryCount > 0 && _vm.error.length === 0 ? _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveData.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _vm.csv.length > 0 && _vm.error.length === 0 ? _c('div', {
    staticClass: "csv_file_upload__confirm_text"
  }, [_c('p', [_vm._v("先生を【" + _vm._s(_vm.entryCount) + "名】登録します。")])]) : _vm._e(), _vm.csv.length > 0 && _vm.error.length === 0 ? _c('section', {
    staticClass: "fake_scroll_table"
  }, [_vm._m(0), _vm._l(_vm.csv, function (row, index) {
    return _c('div', {
      key: index,
      staticClass: "fake_scroll_table__body"
    }, [index > 0 ? _c('div', {
      class: {
        fake_scroll_table__tr: true,
        skip: _vm.skipEmails.find(v => v === row[1])
      }
    }, [_c('p', {
      staticClass: "fake_scroll_table__span col_4"
    }, [_vm._v(" " + _vm._s(row[0]) + " ")]), _c('p', {
      staticClass: "fake_scroll_table__span col_4"
    }, [_vm._v(" " + _vm._s(row[1]) + " ")]), _c('p', {
      staticClass: "fake_scroll_table__span col_2"
    }, [_vm._v(" " + _vm._s(row[2]) + " ")]), _c('p', {
      staticClass: "fake_scroll_table__span col_2"
    }, [_vm._v(" " + _vm._s(row[3]) + " ")])]) : _vm._e()]);
  })], 2) : _vm._e(), _vm.notice.length > 0 ? _c('div', {
    staticClass: "csv_file_upload__section"
  }, [_c('ul', _vm._l(_vm.notice, function (n, i) {
    return _c('li', {
      key: i,
      staticClass: "notice"
    }, [_vm._v(" " + _vm._s(n) + " ")]);
  }), 0)]) : _vm._e(), _vm.csv.length > 0 && _vm.error.length > 0 ? _c('section', [_c('table', {
    staticClass: "error_table"
  }, [_c('tbody', _vm._l(_vm.csv, function (row, i) {
    return _c('tr', {
      key: i,
      class: i === 0 ? 'error_table_head' : ''
    }, [_c('th', [_vm._v(_vm._s(i + 1) + "行目")]), _vm._l(row, function (cell, j) {
      return _c('td', {
        key: j,
        class: {
          error: _vm.tableError[i][j]
        }
      }, [_vm._v(" " + _vm._s(cell) + " ")]);
    })], 2);
  }), 0)])]) : _vm._e(), !_vm.$route.params.id && _vm.csv.length > 0 && _vm.error.length === 0 ? _c('row-item', {
    staticClass: "teacher_item start teacher_item_margin"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("先生へのお知らせ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form__radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.notifyRegistered,
      expression: "notifyRegistered"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "notify_registered",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.notifyRegistered) ? _vm._i(_vm.notifyRegistered, true) > -1 : _vm.notifyRegistered
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.notifyRegistered,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.notifyRegistered = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.notifyRegistered = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.notifyRegistered = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "notify_registered"
    }
  }, [_vm._v(" お知らせを送信する ")]), _c('p', {
    staticClass: "notice"
  }, [_vm._v(" ※お知らせを送信するにチェックを入れると登録された先生にログイン方法のご案内を送ります。 ")])])])]) : _vm._e()], 1)]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.completeCreate
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "fake_scroll_table__head"
  }, [_c('div', {
    staticClass: "fake_scroll_table__tr"
  }, [_c('p', {
    staticClass: "fake_scroll_table__heading col_4"
  }, [_vm._v("氏名")]), _c('p', {
    staticClass: "fake_scroll_table__heading col_4"
  }, [_vm._v("メールアドレス")]), _c('p', {
    staticClass: "fake_scroll_table__heading col_2"
  }, [_vm._v("権限")]), _c('p', {
    staticClass: "fake_scroll_table__heading col_2"
  }, [_vm._v("学年組")])])]);
}];
export { render, staticRenderFns };