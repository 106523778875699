import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import moment from "moment";
import { getData } from "@/services/axios";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "MessageHistories",
  components: {
    RowItem
  },
  data: () => ({
    fiscalYear: moment().add(-3, "month").year(),
    filterOptions: {
      keywordFilter: "",
      dateFilter: "",
      datepickerShortcuts: [{
        label: "今日",
        value: "day",
        key: "Today"
      }, {
        label: "先週",
        value: "-week",
        key: "LastWeek"
      }, {
        label: "今週",
        value: "week",
        key: "ThisWeek"
      }, {
        label: "先月",
        value: "-month",
        key: "LastMonth"
      }, {
        label: "今月",
        value: "month",
        key: "ThisMonth"
      }, {
        label: "去年",
        value: "-year",
        key: "LastYear"
      }, {
        label: "今年",
        value: "year",
        key: "ThisYear"
      }]
    },
    isLoading: true,
    showModal: false,
    title: "",
    messages: [],
    filteredMessages: [],
    intervalId: undefined,
    listModified: moment().format("HH:mm:ss"),
    listUpdating: false
  }),
  computed: { ...mapState(["fiscalYears"]),
    numberOfStatuses: function () {
      return this.filteredMessages.reduce((p, v) => p + v.counts + v.participant_counts, 0);
    },
    numberOfPublishedMessages: function () {
      return this.filteredMessages.filter(v => v.status === "published").length;
    }
  },

  created() {
    this.setData();
    this.intervalId = setInterval(() => {
      if (!this.listUpdating) {
        this.setData();
      }
    }, 1000 * 60);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  methods: {
    isPastList(message) {
      return moment(message.message_scheduled_for).add(-3, "month").year() < moment().add(-3, "month").year();
    },

    setData() {
      this.listUpdating = true;
      getData(["reader/get_message_histories"]).then(data => {
        this.messages = data[0].items.map(v => {
          v.teacher_name = v.teacher?.name ?? "";
          v.message_scheduled_for_date = moment(v.message_scheduled_for).format("YYYY年MM月DD日(ddd) HH:mm");
          return v;
        });
        this.filteredMessages = this.messages.filter(v => {
          return moment(v.message_scheduled_for) > moment(Number(this.fiscalYear) + "-04-01") && moment(v.message_scheduled_for) < moment(Number(this.fiscalYear) + 1 + "-04-01");
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading = false;
        this.listUpdating = false;
        this.listModified = moment().format("HH:mm:ss");
      });
    },

    doFilter() {
      let messages = this.messages;
      const keyword = this.filterOptions.keywordFilter;
      const momentDateStart = this.filterOptions.dateFilter.start ? moment(this.filterOptions.dateFilter.start).startOf("day") : false;
      const momentDateEnd = this.filterOptions.dateFilter.end ? moment(this.filterOptions.dateFilter.end).startOf("day") : false;

      if (keyword) {
        messages = messages.filter(v => Object.keys(v).some(k => String(v[k]).indexOf(keyword) > -1));
      }

      if (momentDateStart && !momentDateEnd) {
        messages = messages.filter(v => momentDateStart.isBefore(moment(v.message_scheduled_for).startOf("day")));
      }

      if (momentDateEnd && !momentDateStart) {
        messages = messages.filter(v => momentDateEnd.isAfter(moment(v.message_scheduled_for).startOf("day")));
      }

      if (momentDateStart && momentDateEnd) {
        if (momentDateStart.isSame(momentDateEnd)) {
          messages = messages.filter(v => momentDateStart.isSame(moment(v.message_scheduled_for).startOf("day")));
        } else {
          messages = messages.filter(v => moment(v.message_scheduled_for).isBetween(momentDateStart, momentDateEnd, null, "[]"));
        }
      }

      messages = messages.filter(v => {
        return moment(v.message_scheduled_for) > moment(Number(this.fiscalYear) + "-04-01") && moment(v.message_scheduled_for) < moment(Number(this.fiscalYear) + 1 + "-04-01");
      });
      this.filteredMessages = messages;
    },

    handleMessageDate(message) {
      switch (message.status) {
        case "published":
        case "sending":
        case "redial":
          return message.message_scheduled_for_date;

        default:
          return "";
      }
    },

    getMessageStatusJP(message) {
      switch (message.status) {
        case "draft":
          return "下書き";

        case "published":
          return "送信完了";

        case "reserved":
          if (-60 <= moment().diff(message.message_scheduled_for, "seconds")) {
            return "送信準備中";
          } else {
            return "予約済";
          }

        case "sending":
          return "送信中";

        case "redial":
          return "リダイヤル中";

        case "reviewing":
          return "承認依頼中";

        default:
          return "不明";
      }
    }

  }
};