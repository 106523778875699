import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('div', [_vm.isLoading.status ? _c(_setup.LoadingMessage) : _vm._e(), _vm.isLoading.postData ? _c(_setup.LoadingMessage, {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _c('main', {
    staticClass: "main page_information_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_vm.is_switch_visibility ? _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("共有範囲")]), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button__small"
  }, [_c('button', {
    staticClass: "switch_button__left item",
    class: {
      active: _setup.message.visibility === 'default'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setMessageVisibility('default');
      }
    }
  }, [_vm._v(" 学内共有 ")]), _c('button', {
    staticClass: "switch_button__right item",
    class: {
      active: _setup.message.visibility === 'limited'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.setMessageVisibility('limited');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/keylock.svg"),
      "alt": "keylock",
      "width": "14px"
    }
  }), _vm._v("限定共有 ")])]), _vm._v(" 限定共有は、管理者権限・作成者の先生のみ配信内容を確認できます。 ")])]) : _vm._e(), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_c('label', {
    attrs: {
      "for": "templateName"
    }
  }, [_vm._v("定型文")])]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.template,
      expression: "message.template"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "templateName"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_setup.message, "template", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("選択してください")]), _vm._l(_vm.messageTemplates, function (template, index) {
    return _c('option', {
      key: template.id,
      domProps: {
        "value": index
      }
    }, [_vm._v(" " + _vm._s(template.template_name ? template.template_name : template.subject) + " ")]);
  })], 2), _c('button', {
    staticClass: "read_button",
    attrs: {
      "disabled": _setup.message.template === false
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showConfirmMessage('setTemplate');
      }
    }
  }, [_vm._v(" 呼び出す ")])])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("送信先")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    staticClass: "select_button",
    attrs: {
      "type": "button",
      "value": "送信先を選択"
    },
    on: {
      "click": _vm.selectTo
    }
  }), _setup.message.sendTargets.students.length > 0 || _setup.message.sendTargets.participants.length > 0 ? _c('input', {
    staticClass: "select_button",
    attrs: {
      "type": "button",
      "value": "送信先を確認"
    },
    on: {
      "click": _vm.confirmTo
    }
  }) : _vm._e(), _c('input', {
    staticClass: "select_clear_button",
    attrs: {
      "type": "button",
      "value": "選択解除"
    },
    on: {
      "click": function ($event) {
        return _vm.showConfirmMessage('clearTarget');
      }
    }
  }), _c('span', {
    staticClass: "selected_count"
  }, [_vm._v(_vm._s(_setup.message.sendTargets.students.length + _setup.message.sendTargets.participants.length) + "人選択中")]), _vm.error.sendTargets ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.sendTargets) + " ")]) : _vm._e()])]), _vm.capabilities.enableEmergencyCall ? _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("お知らせの種別")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.is_emergency,
      expression: "message.is_emergency"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "is_not_emergency",
      "type": "radio",
      "name": "emergency",
      "value": "0"
    },
    domProps: {
      "checked": _vm._q(_setup.message.is_emergency, "0")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message, "is_emergency", "0");
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "is_not_emergency"
    }
  }, [_vm._v("通常")])]), _c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.is_emergency,
      expression: "message.is_emergency"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "is_emergency",
      "type": "radio",
      "name": "emergency",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_setup.message.is_emergency, "1")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message, "is_emergency", "1");
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "is_emergency"
    }
  }, [_vm._v("緊急")])]), _c('br'), _c('p', [_vm._v(" ※緊急のお知らせについては、電話への配信を設定している保護者もいるなど、通常のお知らせとは配信方法が異なります ")])])]) : _vm._e(), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("配信予約時間")]), _c('div', {
    staticClass: "col_5"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "id": "message_scheduled_for",
      "label": "予約日時",
      "no-button-now": "",
      "locale": "ja",
      "format": "YYYY-MM-DD HH:mm",
      "output-format": "YYYY-MM-DD HH:mm:ss",
      "minute-interval": "5",
      "no-clear-button": "",
      "min-date": _setup.today,
      "max-date": _setup.threeYearsLater
    },
    model: {
      value: _setup.message.message_scheduled_for,
      callback: function ($$v) {
        _vm.$set(_setup.message, "message_scheduled_for", $$v);
      },
      expression: "message.message_scheduled_for"
    }
  })], 1)]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("有効期限")]), _c('div', {
    staticClass: "col_5"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "id": "expire_on",
      "label": "有効期限",
      "no-button-now": "",
      "only-date": "",
      "locale": "ja",
      "formatted": "YYYY年MM月DD日",
      "output-format": "YYYY-MM-DD",
      "no-clear-button": "",
      "min-date": _setup.today,
      "max-date": _setup.threeYearsLater
    },
    model: {
      value: _setup.message.expire_on,
      callback: function ($$v) {
        _vm.$set(_setup.message, "expire_on", $$v);
      },
      expression: "message.expire_on"
    }
  })], 1)]), _c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("メールの設定")]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メールの件名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _setup.message.subject,
      expression: "message.subject",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _setup.message.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_setup.message, "subject", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.error.subject ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.subject) + " ")]) : _vm._e()])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メッセージ本文"), _c('br'), _vm._v("（3000文字まで）")]), _c('div', {
    staticClass: "col_9"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.mail_body,
      expression: "message.mail_body"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "",
      "name": "body",
      "rows": "10"
    },
    domProps: {
      "value": _setup.message.mail_body
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_setup.message, "mail_body", $event.target.value);
      }, _vm.handleInputMailBody]
    }
  }), _vm._v(" "), _vm.error.mail_body ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.mail_body) + " ")]) : _vm._e(), !_setup.isUrlValidate ? _c('p', {
    staticClass: "warning"
  }, [_vm._v(" URLは1行に1つずつ記載してください。URLの前後に文字や記号、行内に複数のURLが含まれるとリンクが正しく機能しません。 ")]) : _vm._e()])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("添付ファイル")]), _c('div', {
    staticClass: "col_9"
  }, [_vm.s3_attached_path ? _c('div', [_c('button', {
    staticClass: "status_button",
    attrs: {
      "disabled": _vm.getS3UrlStatusRunning
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.getS3SignedUrl.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getS3UrlStatus) + " ")]), _c('button', {
    staticClass: "file_button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showConfirmMessage('unsetS3Attachment');
      }
    }
  }, [_vm._v(" ファイルを差し替え ")])]) : _c('div', [_c('input', {
    attrs: {
      "id": "file",
      "type": "file",
      "value": "参照"
    },
    on: {
      "change": _vm.setAttachment
    }
  }), _c('label', {
    staticClass: "file_button",
    attrs: {
      "for": "file"
    }
  }, [_vm._v("参照")]), _setup.message.attachment ? _c('span', [_vm._v(_vm._s(_setup.message.attachment.name) + " ["), _c('a', {
    attrs: {
      "href": ""
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.unsetAttachment.apply(null, arguments);
      }
    }
  }, [_vm._v("x")]), _vm._v("] ")]) : _vm._e()]), _vm.error.attachment ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.attachment) + " ")]) : _vm._e(), _c('p', [_vm._v(" 1件のPDF・画像（pdf,jpg,jpeg,png,gif）ファイルを添付できます（5MBまで） ")]), _c('p', [_vm._v(" 重要：添付ファイルはお知らせ方法が「電話」となっている保護者には確認できません。 ")])])])], 1), _c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("電話連絡の設定")]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("自動再配信")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.settings.auto_redial.use,
      expression: "message.settings.auto_redial.use"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "auto_submit_no",
      "type": "radio",
      "name": "auto_submit"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_setup.message.settings.auto_redial.use, false)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message.settings.auto_redial, "use", false);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "auto_submit_no"
    }
  }, [_vm._v("なし")])]), _c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.settings.auto_redial.use,
      expression: "message.settings.auto_redial.use"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "auto_submit_yes",
      "type": "radio",
      "name": "auto_submit"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_setup.message.settings.auto_redial.use, true)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message.settings.auto_redial, "use", true);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "auto_submit_yes"
    }
  }, [_vm._v("あり")])]), _setup.message.settings.auto_redial.use ? [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.settings.auto_redial.interval,
      expression: "message.settings.auto_redial.interval"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "after_minute"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_setup.message.settings.auto_redial, "interval", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(4, function (interval) {
    return _c('option', {
      key: interval,
      domProps: {
        "value": 15 * interval
      }
    }, [_vm._v(" " + _vm._s(15 * interval) + " ")]);
  }), 0), _c('label', {
    attrs: {
      "for": "after_minute"
    }
  }, [_vm._v("分後")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.settings.auto_redial.iteration,
      expression: "message.settings.auto_redial.iteration"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "after_number"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_setup.message.settings.auto_redial, "iteration", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(5, function (iteration) {
    return _c('option', {
      key: iteration,
      domProps: {
        "value": iteration
      }
    }, [_vm._v(" " + _vm._s(iteration) + " ")]);
  }), 0), _c('label', {
    attrs: {
      "for": "after_number"
    }
  }, [_vm._v("回")])] : _vm._e()], 2)]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("確認文（自動で読み上げられます）")]), _c('div', {
    staticClass: "col_9"
  }, [_vm._v(" はじめに、シャープ 又は コメマークを押してください。 ")])]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("メッセージ本文"), _c('br'), _vm._v("（200文字まで）")]), _c('div', {
    staticClass: "col_9"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _setup.message.call_body,
      expression: "message.call_body",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "tel_body",
      "name": "tel_body",
      "rows": "10"
    },
    domProps: {
      "value": _setup.message.call_body
    },
    on: {
      "keydown": _vm.detectCallBodyInput,
      "input": function ($event) {
        if ($event.target.composing) return;

        _vm.$set(_setup.message, "call_body", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm._v(" "), _vm.error.call_body ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.call_body) + " ")]) : _vm._e()])])], 1), _c('section', {
    staticClass: "gray_frame"
  }, [_c('div', {
    staticClass: "col_pull_down__heading"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("アンケートの設定")]), _c(_setup.RowItem, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("アンケートを使用する")]), _c('div', {
    staticClass: "col_9"
  }, [_c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.useEnquete,
      expression: "message.useEnquete"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "survey_create_yes",
      "type": "radio",
      "name": "survey_create"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_setup.message.useEnquete, true)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message, "useEnquete", true);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "survey_create_yes"
    }
  }, [_vm._v("する")])]), _c('div', {
    staticClass: "common_form--radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.message.useEnquete,
      expression: "message.useEnquete"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "survey_create_no",
      "type": "radio",
      "name": "survey_create"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_setup.message.useEnquete, false)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_setup.message, "useEnquete", false);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "survey_create_no"
    }
  }, [_vm._v("しない")])])])])], 1), _setup.message.useEnquete ? _c(_setup.InputEnquete) : _vm._e()], 1), _vm.error.total ? _c('pre', {
    staticClass: "error pre"
  }, [_vm._v(_vm._s(_vm.error.total.join("\n")))]) : _vm._e(), _c('div', {
    staticClass: "tac"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'messages'
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('input', {
    staticClass: "save_button",
    attrs: {
      "type": "button",
      "value": "下書き保存"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.saveMessage('draft');
      }
    }
  })], 1), _c('div', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "save_button",
    attrs: {
      "type": "button",
      "value": "承認依頼"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openPreviewModal(true);
      }
    }
  })]), _vm.me.role_id <= 11 || _vm.school.meta && _vm.school.meta.allowNoSignatureMessaging ? _c('div', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "submit_button",
    attrs: {
      "type": "button",
      "value": "お知らせを送信する"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.openPreviewModal.apply(null, arguments);
      }
    }
  }), _c('br'), _c('br'), _c('br'), _c('br'), _c('br')]) : _vm._e()], 1), _vm.showPreviewModal ? _c(_setup.PreviewModal, {
    attrs: {
      "message": _setup.message,
      "enquete": _vm.enquete,
      "process-reviewing": _vm.processReviewing
    },
    on: {
      "click-check-target": _vm.confirmTo,
      "click-send": function ($event) {
        return _vm.saveMessage('reserved');
      },
      "click-request-approval": function ($event) {
        return _vm.saveMessage('requestApproval');
      },
      "click-cancel": _vm.cancelPreview,
      "click-preview-attachment": _vm.getS3SignedUrl
    }
  }) : _vm._e(), _vm.showCompleteModal ? _c(_setup.MessageModal, {
    on: {
      "close": _vm.completeCreate
    }
  }) : _vm._e(), _vm.showStudentSelectModal ? _c(_setup.StudentSelector, {
    attrs: {
      "send-targets": _setup.message.sendTargets,
      "confirm-mode": _vm.confirmMode
    },
    on: {
      "update:sendTargets": function ($event) {
        return _vm.$set(_setup.message, "sendTargets", $event);
      },
      "update:send-targets": function ($event) {
        return _vm.$set(_setup.message, "sendTargets", $event);
      },
      "close": _vm.finishSelectStudents
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c(_setup.ConfirmModal, {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.callFunction,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };