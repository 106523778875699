import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import store from "../../services/store.js";
import { deleteData, getData, updateData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import { mapState } from "vuex";
import LoadingMessage from "@/components/common/LoadingMessage";
import ConfirmModal from "@/components/common/ConfirmModal";
import RowItem from "../RowItem";
export default {
  store,
  name: "AttendanceIndex",
  components: {
    RowItem,
    MessageModal,
    LoadingMessage,
    ConfirmModal
  },

  data() {
    return {
      showModal: false,
      student: {
        guardian: []
      },
      isLoading: {
        getData: true,
        handleData: false
      },
      loadingMessage: "",
      guardiansLimit: process.env.VUE_APP_GUARDIANS_LIMIT,
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      targetId: null,
      action: ""
    };
  },

  computed: { ...mapState(["studentAddress", "me", "capabilities"])
  },

  created() {
    this.setData();
  },

  methods: {
    setData() {
      getData("students/" + this.$route.params.id + "?with[]=guardian&with[]=classroom").then(data => {
        if (data) {
          this.student = data.items[0];

          if (this.student.guardian) {
            const paths = this.student.guardian.map(v => "guardians/" + v.id + "?with=user");
            getData(paths).then(data => {
              if (data) {
                this.student.guardian = data.map(v => v.items[0]);
              }
            });
          }
        }
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.getData = false;
      });
    },

    labelLocale(key) {
      const localeMap = {
        "ja-JP": "日本語",
        "en-US": "English",
        "es-US": "Español",
        "zh-CN": "中国語（簡体字）"
      };
      return localeMap[key];
    },

    confirmRemoveStudent(id) {
      this.action = "deleteStudent";
      this.targetId = id;
      this.confirmMessageTitle = this.studentAddress + "削除";
      this.confirmMessage = this.studentAddress + "情報を削除します。\n実行すると、登録されている保護者の情報も同時に削除されます。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },

    confirmRemoveGuardian(id) {
      this.action = "deleteGuardian";
      this.targetId = id;
      this.confirmMessageTitle = "保護者情報削除";
      this.confirmMessage = "保護者情報を削除します。\nアンケート回答権を持つ保護者を削除した場合、他の保護者に自動的に引き継がれますが、別の保護者に引き継ぎたい場合は保護者編集から修正してください。\n本当に実行してもよろしいですか？";
      this.showConfirmModal = true;
    },

    confirmSwitchingPrimaryGuardian(id) {
      this.action = "switchPrimaryGuardian";
      this.targetId = id;
      const target = this.student.guardian.find(v => v.id === id);

      if (target) {
        this.confirmMessageTitle = "アンケート回答者変更";
        this.confirmMessage = target.name + "さんをアンケート回答者に指定します。\nそれ以外の保護者はアンケートに回答できなくなります。\n本当に実行してもよろしいですか？";
        this.showConfirmModal = true;
      }
    },

    handleData() {
      this.isLoading.handleData = true;

      switch (this.action) {
        case "deleteStudent":
          this.loadingMessage = this.studentAddress + "情報を削除しています。";
          deleteData("eraser/delete_student/" + this.targetId).then(() => {
            this.$store.dispatch("commitModalMessage", {
              message: this.studentAddress + "情報が削除されました",
              title: "削除完了"
            });
            this.showModal = true;
          }).catch(error => {
            console.log(error);
            alert(this.studentAddress + "情報の削除に失敗しました");
          }).then(() => {
            this.isLoading.handleData = false;
            this.showConfirmModal = false;
          });
          break;

        case "deleteGuardian":
          this.loadingMessage = "保護者情報を削除しています。";
          deleteData("eraser/delete_guardian/" + this.targetId + "?student_id=" + this.student.id).then(() => {
            this.$store.dispatch("commitModalMessage", {
              message: "保護者情報が削除されました",
              title: "削除完了"
            });
            this.showModal = true;
          }).catch(error => {
            console.log(error);
            alert("保護者情報削除に失敗しました");
          }).then(() => {
            this.isLoading.handleData = false;
            this.showConfirmModal = false;
          });
          break;

        case "switchPrimaryGuardian":
          {
            const guardian = this.student.guardian.find(v => v.id === this.targetId);
            guardian.email = guardian.user.email;
            guardian.role_id = 21;
            guardian.target_student_id = this.student.id;
            this.loadingMessage = "アンケート回答者を変更しています。";
            updateData("writer/write_guardian/" + guardian.id, {
              guardian
            }).then(() => {
              this.$store.dispatch("commitModalMessage", {
                message: "アンケート回答者の変更が完了しました",
                title: "変更完了"
              });
              this.showModal = true;
            }).catch(error => {
              console.log(error);
              alert("アンケート回答者の変更が失敗しました");
            }).then(() => {
              this.isLoading.handleData = false;
              this.showConfirmModal = false;
            });
          }
      }
    },

    cancelConfirm() {
      this.showConfirmModal = false;
    },

    closeModal() {
      switch (this.action) {
        case "deleteStudent":
          this.$router.push({
            name: "students"
          });
          break;

        case "deleteGuardian":
        case "switchPrimaryGuardian":
          this.isLoading.getData = true;
          this.setData();
          break;
      }

      this.showModal = false;
    }

  }
};