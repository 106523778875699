import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main"
  }, [_setup.isLoading ? _c(_setup.LoadingMessage) : _vm._e(), _c('h1', {
    staticClass: "page-title"
  }, [_vm._v("学習支援ツールIDとの連携")]), _c('p', {
    staticClass: "page-description"
  }, [_vm._v(" 学習支援ツールのIDと利用者の紐づけを設定します ")]), _setup.dekitusIds.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_setup.dekitusIds, function (dekitusId) {
    return _c(_setup.SettingRow, {
      key: dekitusId,
      attrs: {
        "grades": _setup.grades,
        "dekitus-id": dekitusId,
        "current-student": _setup.dekitusStudents.find(v => v.dekitus_id === dekitusId) ?? {},
        "dekitus-students": _setup.dekitusStudents
      },
      on: {
        "handleStudentChange": _setup.handleStudentChange
      }
    });
  }), 1)]) : _c(_setup.DekitusIntroduction), _setup.dekitusIds.length > 0 ? _c('button', {
    staticClass: "button-register",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.handleSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v(" 登録する ")]) : _vm._e(), _setup.isShowCompleteModal ? _c('MessageModal', {
    on: {
      "close": function ($event) {
        _setup.isShowCompleteModal = false;
      }
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("学年")]), _c('th', [_vm._v("クラス")]), _c('th', [_vm._v("氏名")])])]);
}];
export { render, staticRenderFns };