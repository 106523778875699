import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { mapState } from "vuex";
import { iconColor } from "@/consts/iconColor";
export default {
  name: "GradeTable",
  props: {
    todayAttendance: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      attendanceList: {}
    };
  },

  computed: { ...mapState(["grades"])
  },
  watch: {
    todayAttendance: {
      immediate: true,

      handler() {
        this.attendanceList = {};
        this.grades.forEach(grade => {
          this.attendanceList[grade.name] = {};
          grade.classroom.forEach(classroom => {
            const attendanceAlignmentList = [[], [], [], [], [], [], []];
            this.todayAttendance.filter(attendance => attendance.student.grade_id === grade.id && attendance.student.class_id === classroom.id).forEach(attendance => {
              attendance.isAbsentList3 = attendance.absents.find(v => v === 3);
              attendance.isAbsentList20 = attendance.absents.find(v => v === 20);
              attendance.isAbsentList30 = attendance.absents.find(v => v === 30); // 暫定的に、欠席→遅刻→早退→遅刻早退→登校→下校->未登校の順に表示する

              const type = attendance.type.split("-")[0];

              switch (type) {
                case "欠席":
                  attendanceAlignmentList[0].push(attendance);
                  break;

                case "遅刻":
                  attendanceAlignmentList[1].push(attendance);
                  break;

                case "早退":
                  attendanceAlignmentList[2].push(attendance);
                  break;

                case "遅刻早退":
                  attendanceAlignmentList[3].push(attendance);
                  break;

                case "登校":
                  attendanceAlignmentList[4].push(attendance);
                  break;

                case "下校":
                  attendanceAlignmentList[5].push(attendance);
                  break;

                case "未登校":
                  attendanceAlignmentList[6].push(attendance);
                  break;
              }
            });
            this.attendanceList[grade.name][classroom.name] = attendanceAlignmentList.flat();
          });
        });
      }

    }
  },
  methods: {
    iconColor
  }
};