import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import moment from "moment";
import { ref, computed } from "vue";
import { getData } from "@/services/axios";
import { useRoute, useRouter } from "vue-router/composables";
import { showSumHoursMinutesAndSeconds } from "@/services/common";

import { watch } from 'vue';
export default {
  __name: 'Detail',

  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const lessonResults = ref([]);
    const drillResults = ref([]);
    const student = ref({});
    const isLoading = ref(false);
    const localYearMonth = computed(() => {
      return moment(route.query.year_month ?? new Date()).format("YYYY年MM月");
    });

    const setStudentData = async () => {
      try {
        const students = await getData(`students/${route.query.student_id}`);

        if (students) {
          student.value = students.items[0];
        }
      } catch (error) {
        console.log(error);
      }
    };

    const setStudyResultData = async () => {
      try {
        isLoading.value = true;
        const data = await getData(`dekitus_study_results?student_id=${route.query.student_id}&year_month=${route.query.year_month ?? ""}`);
        lessonResults.value = data.items.filter(v => v.type.indexOf("授業") > -1);
        drillResults.value = data.items.filter(v => v.type.indexOf("授業") === -1);
        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        console.log(error);
      }
    };

    const monthlyLessonSummary = computed(() => {
      if (lessonResults.value) {
        return showSumHoursMinutesAndSeconds(lessonResults.value.map(v => v.time));
      } else {
        return "";
      }
    });
    const monthlyDrillSummary = computed(() => {
      if (drillResults.value) {
        return showSumHoursMinutesAndSeconds(drillResults.value.map(v => v.time));
      } else {
        return "";
      }
    });

    const monthChange = mode => {
      if (!isLoading.value) {
        const date = moment(route.query.year_month ?? new Date());

        if (mode === "add") {
          date.add(1, "months");
        } else if (mode === "sub") {
          date.subtract(1, "months");
        }

        router.push({
          query: Object.assign({}, route.query, {
            year_month: date.format("YYYY-MM")
          })
        });
      }
    };

    watch(() => route.query.year_month, async () => {
      await setStudyResultData();
    });
    setStudentData();
    setStudyResultData();
    return {
      __sfc: true,
      route,
      router,
      lessonResults,
      drillResults,
      student,
      isLoading,
      localYearMonth,
      setStudentData,
      setStudyResultData,
      monthlyLessonSummary,
      monthlyDrillSummary,
      monthChange
    };
  }

};