import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import moment from "moment";
import { getData } from "@/services/axios";
import RowItem from "../RowItem";
import { mapState } from "vuex";
export default {
  name: "EnqueteIndex",
  components: {
    RowItem
  },
  data: () => {
    return {
      fiscalYear: moment().add(-3, "month").year(),
      isLoading: {
        enquete: true
      },
      filterOptions: {
        keyword: "",
        date: ""
      },
      enquetes: [],
      filteredEnquetes: []
    };
  },
  computed: { ...mapState(["fiscalYears"])
  },

  created() {
    this.setData();
  },

  methods: {
    isPastList(enquete) {
      return moment(enquete.implement_to).add(-3, "month").year() < moment().add(-3, "month").year();
    },

    setData() {
      getData(["reader/get_enquete_list"]).then(data => {
        this.enquetes = data[0].items.map(v => {
          const answerRate = v.answered === 0 || v.targets === 0 ? 0 : Math.round(v.answered / v.targets * 1000) / 10;
          return {
            id: v.id,
            subject: v.subject,
            answered: v.answered,
            targets: v.targets,
            answer_rate: answerRate,
            created_at: v.created_at,
            implement_to: v.implement_to,
            teacher_name: v.teacher_name,
            visibility: v.visibility,
            is_visible: v.is_visible
          };
        });
        this.filteredEnquetes = this.enquetes.filter(v => {
          return moment(v.implement_to) > moment(Number(this.fiscalYear) + "-04-01") && moment(v.implement_to) < moment(Number(this.fiscalYear) + 1 + "-04-01");
        });
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.enquete = false;
      });
    },

    filterList() {
      let enquetes = this.enquetes;

      if (this.filterOptions.date) {
        enquetes = enquetes.filter(v => moment(v.implement_to).startOf("day").isSame(moment(this.filterOptions.date).startOf("day")));
      }

      if (this.filterOptions.keyword) {
        enquetes = enquetes.filter(v => Object.keys(v).some(k => String(v[k]).indexOf(this.filterOptions.keyword) > -1));
      }

      enquetes = enquetes.filter(v => {
        return moment(v.implement_to) > moment(Number(this.fiscalYear) + "-04-01") && moment(v.implement_to) < moment(Number(this.fiscalYear) + 1 + "-04-01");
      });
      this.filteredEnquetes = enquetes;
    },

    formatDate(date) {
      return moment(date).format("YYYY年MM月DD日 HH:mm");
    }

  }
};