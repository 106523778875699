import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
// @ts-check

/** @param {Ref<MonthData[]> | MonthData[]} maybeRefMonthData */
export const useMonthlyYearlySummary = maybeRefMonthData => {
  const monthData = unref(maybeRefMonthData);
  const schoolDayLength = computed(() => monthData.filter(v => !v.isSchoolDayOff).length);

  const getSummaryTemplate = () => {
    return {
      停忌等: 0,
      要出席日数: 0,
      "欠席（病気）": 0,
      "欠席（事故）": 0,
      出席日数: 0,
      遅刻日数: 0,
      早退日数: 0
    };
  }; // 生徒ごとの出席状況


  const aggregateStudentSummary = (summary, countingType) => {
    if (countingType === "停忌等") {
      summary["停忌等"]++;
      summary["要出席日数"]--;
      summary["出席日数"]--;
    } else if (countingType === "病気欠席") {
      summary["欠席（病気）"]++;
      summary["出席日数"]--;
    } else if (countingType === "事故欠席") {
      summary["欠席（事故）"]++;
      summary["出席日数"]--;
    } else if (countingType === "遅刻") {
      summary["遅刻日数"]++;
    } else if (countingType === "早退") {
      summary["早退日数"]++;
    } else if (countingType === "遅早") {
      summary["遅刻日数"]++;
      summary["早退日数"]++;
    }
  }; // 欠席理由概要の計算


  const aggregateMonthlyTypeSummary = (v, monthlyTypeSummary) => {
    const typeSplit = v.type.split("-");

    if (typeSplit[0] === "欠席") {
      appendMonthlyTypeSummary(typeSplit[1], monthlyTypeSummary);
    } else if (typeSplit[0] === "遅刻" || typeSplit[0] === "早退") {
      appendMonthlyTypeSummary(typeSplit[0], monthlyTypeSummary);
    } else if (typeSplit[0] === "遅刻早退") {
      appendMonthlyTypeSummary("遅刻", monthlyTypeSummary);
      appendMonthlyTypeSummary("早退", monthlyTypeSummary);
    }
  };

  const appendMonthlyTypeSummary = (key, monthlyTypeSummary) => {
    if (monthlyTypeSummary[key]) {
      monthlyTypeSummary[key]++;
    } else {
      monthlyTypeSummary[key] = 1;
    }
  };

  return {
    schoolDayLength,
    getSummaryTemplate,
    aggregateStudentSummary,
    aggregateMonthlyTypeSummary
  };
};