import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
// @ts-check
import moment from "moment";
import { useStore } from "vue2-helpers/vuex";
import { getData, downloadData } from "@/services/axios";
import { attendanceLabels } from "@/consts/attendanceLabels";
export const useDownloadAttendanceBook = (filter, monthData, dailySummary, dailyEvents, students, classroom) => {
  const store = useStore();
  const school = computed(() => store.state.school);
  /** @type ComputedRef<string[]> */

  const holidays = computed(() => store.state.holidays);
  const {
    schoolDayLength,
    getSummaryTemplate,
    aggregateStudentSummary,
    aggregateMonthlyTypeSummary
  } = useMonthlyYearlySummary(monthData);
  const {
    isLoading
  } = storeToRefs(useLoadingStore());
  const localYearMonth = computed(() => {
    return moment(filter.yearMonth).format("YYYY年MM月");
  });

  const schoolDaysOfYearLength = schoolOffDaysOfYear => {
    const yearMonth = moment(filter.yearMonth).add(1, "month").startOf("month");
    return Object.keys(schoolOffDaysOfYear).filter(k => !schoolOffDaysOfYear[k] && yearMonth.isAfter(moment(k))).length;
  };

  const schoolDaysOfYearBeforeTodayLength = schoolOffDaysOfYear => {
    const yearMonth = moment(filter.yearMonth).add(1, "month").startOf("month");
    const today = moment();

    if (today.isBefore(yearMonth)) {
      return Object.keys(schoolOffDaysOfYear).filter(k => !schoolOffDaysOfYear[k] && today.isAfter(moment(k))).length;
    } else {
      return Object.keys(schoolOffDaysOfYear).filter(k => !schoolOffDaysOfYear[k] && yearMonth.isAfter(moment(k))).length;
    }
  };

  const downloadAttendanceBook = async () => {
    isLoading.value = true; // 今年度と来年度の初日を取得する

    const fiscalDate = moment(filter.yearMonth).add(-3, "month").month(3).startOf("month");
    const data = await getData(["reader/get_students_with_attendance?classroom_id=" + filter.classroomId + "&group_id=" + filter.groupId + "&fiscal_year=" + fiscalDate.year(), "events?orderby=updated_at&order=asc&classroom_id=" + filter.classroomId + "&fiscal_year=" + fiscalDate.year(), "attendance_monthly_comments?classroom_id=" + filter.classroomId + "&start_of_month=" + moment(filter.yearMonth).startOf("month").format("YYYY-MM-DD")]).catch(error => {
      console.log(error);
    });
    const fiscalYearDaysLength = fiscalDate.clone().add(1, "years").diff(fiscalDate, "day");
    const schoolOffDaysOfYear = {};

    for (let i = 1; i <= fiscalYearDaysLength; i++) {
      const date = fiscalDate.format("YYYY-MM-DD");

      if (fiscalDate.format("d") === "0" || fiscalDate.format("d") === "6" || holidays.value.includes(date)) {
        schoolOffDaysOfYear[date] = true;
      } else {
        schoolOffDaysOfYear[date] = false;
      }

      fiscalDate.add(1, "day");
    }

    const events = data[1].items;
    events.forEach(v => {
      const from = moment(v.from);
      const to = moment(v.to);

      while (from.isSameOrBefore(to, "day")) {
        schoolOffDaysOfYear[from.format("YYYY-MM-DD")] = Boolean(v.is_school_day_off);
        from.add(1, "day");
      }
    });
    const startOfMonth = moment(filter.yearMonth).startOf("month");
    const endOfMonth = moment(filter.yearMonth).endOf("month");
    const yearlySummaryByStudent = {};
    const monthlyTypeSummaryByStudent = {};
    Object.keys(data[0].items).forEach(key => {
      data[0].items[key].forEach(student => {
        const yearlySummary = getSummaryTemplate();
        yearlySummary["要出席日数"] = schoolDaysOfYearLength(schoolOffDaysOfYear);
        yearlySummary["出席日数"] = schoolDaysOfYearBeforeTodayLength(schoolOffDaysOfYear);
        const monthlyTypeSummary = {};
        student.attendance.forEach(v => {
          if (schoolOffDaysOfYear[v.date]) {
            return; // 休日のデータは無視する
          }

          if (!v.counting_type) {
            return; // countingTypeがないときはSummaryに含めない
          }

          const countingType = v.counting_type.counting_type;

          if (moment(v.date).isSameOrBefore(endOfMonth)) {
            // year
            aggregateStudentSummary(yearlySummary, countingType);

            if (moment(v.date).isBetween(startOfMonth, endOfMonth, null, "[]")) {
              aggregateMonthlyTypeSummary(v, monthlyTypeSummary);
            }
          }
        });
        yearlySummaryByStudent[student.id] = yearlySummary;
        monthlyTypeSummaryByStudent[student.id] = monthlyTypeSummary;
      });
    });
    const monthlyTable = [];
    const monthlySummaryTable = [];
    const yearlySummaryTable = [];
    const studentNameTable = [];
    const commentTable = [];
    const monthlyTypeSummaryTable = [];
    students.forEach(v => {
      // 名前一覧
      studentNameTable.push([v.name]); // 月のテーブル

      const monthlyRow = [];
      monthData.forEach(v2 => {
        const k = Object.keys(v.attendanceByDate).find(k => v2["YYYY-MM-DD"] === k);

        if (k) {
          const label = v.attendanceByDate[k].counting_type ? v.attendanceByDate[k].counting_type.glyph : attendanceLabels[v.attendanceByDate[k].type] ? attendanceLabels[v.attendanceByDate[k].type].code : null;
          monthlyRow.push(label);
        } else {
          monthlyRow.push(null);
        }
      });
      monthlyTable.push(monthlyRow); // 生徒ごとの欠席状況

      const m = v.monthlySummary;
      const y = yearlySummaryByStudent[v.id];
      monthlySummaryTable.push([m["停忌等"], m["要出席日数"], m["欠席（病気）"], m["欠席（事故）"], m["出席日数"], m["遅刻日数"], m["早退日数"]]);
      yearlySummaryTable.push([y["停忌等"], y["要出席日数"], y["欠席（病気）"], y["欠席（事故）"], y["出席日数"], y["遅刻日数"], y["早退日数"]]); // コメント

      const attendanceMonthlyComments = data[2].items;
      const comment = attendanceMonthlyComments.find(v2 => v.id === v2.student_id);

      if (comment) {
        commentTable.push([comment.comment]);
      } else {
        commentTable.push([]);
      } // 生徒別のタイプ別合計


      const monthlyTypeSummary = Object.keys(monthlyTypeSummaryByStudent[v.id]).map(k => {
        if (monthlyTypeSummaryByStudent[v.id][k]) {
          return k + ":" + monthlyTypeSummaryByStudent[v.id][k];
        }
      }).filter(v => v);
      monthlyTypeSummaryTable.push([monthlyTypeSummary.join("\n")]);
    }); // 毎日の合計

    const dailySummaryTable = [];
    dailySummary.forEach(value => {
      const rowList = value.map((w, i) => monthData[i].isSchoolDayOff ? "-" : w);
      dailySummaryTable.push(rowList);
    }); // 平日と休日を判別

    const monthlyCalendar = monthData.map(v => {
      return !v.isSchoolDayOff;
    }); // イベント

    const schoolDayOffEventCalendar = monthData.map(v => {
      if (v.isSchoolDayOff && dailyEvents[v["YYYY-MM-DD"]]) {
        return dailyEvents[v["YYYY-MM-DD"]].filter(v2 => v2.is_school_day_off).map(v2 => v2.title).join(",");
      } else {
        return "";
      }
    });
    const filename = "COCOO月別カレンダー" + school.value.name + classroom.name + localYearMonth.value + "_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
    await downloadData("download_attendance_book?classroom_id=" + classroom.id + "&year_month=" + filter.yearMonth, {
      monthly_table: JSON.stringify(monthlyTable),
      daily_summary_table: JSON.stringify(dailySummaryTable),
      monthly_summary_table: JSON.stringify(monthlySummaryTable),
      yearly_summary_table: JSON.stringify(yearlySummaryTable),
      monthly_school_day_length: schoolDayLength.value,
      yearly_school_day_length: schoolDaysOfYearLength(schoolOffDaysOfYear),
      monthly_calendar: JSON.stringify(monthlyCalendar),
      student_name_table: JSON.stringify(studentNameTable),
      comment_table: JSON.stringify(commentTable),
      monthly_type_summary_table: JSON.stringify(monthlyTypeSummaryTable),
      school_day_off_event_calendar: JSON.stringify(schoolDayOffEventCalendar)
    }, filename).catch(error => {
      console.log(error);
    });
    isLoading.value = false;
  };

  return {
    downloadAttendanceBook
  };
};