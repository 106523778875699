import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.structured-clone.js";
import { ref, watch } from "vue";
import moment from "moment";
import { storeToRefs } from "pinia";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";
import { useLoadingStore } from "@/stores/loading";
import { useAbsentAlertStore } from "@/stores/absentAlert";
import MonthlyTableHead from "@/components/attendances/monthly/MonthlyTableHead.vue";
import MonthlySchoolDay from "@/components/attendances/monthly/MonthlySchoolDay.vue";
import MonthDailyEvent from "@/components/attendances/monthly/MonthDailyEvent.vue";
import MonthlyComment from "@/components/attendances/MonthlyComment.vue";
import { useMonthData } from "@/composables/monthData";
import { getData } from "@/services/axios";
export default {
  __name: 'MonthlyTemperatureCalendar',
  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  setup(__props) {
    const props = __props; // @ts-check

    const {
      classrooms
    } = storeToRefs(useGradeClassroomStore());
    const {
      isLoading
    } = storeToRefs(useLoadingStore());
    const absentAlertStore = useAbsentAlertStore();
    const {
      monthData,
      days
    } = useMonthData(props.filter);
    const visibleClassroom = ref([]);
    /** @type Ref<Object<number, MonthData[]>> */

    const classMonthData = ref({});
    const dailyEvents = ref({});
    const students = ref({});

    const setData = async () => {
      isLoading.value = true;

      try {
        const date = moment(props.filter.yearMonth);
        const data = await getData(["events?orderby=updated_at&order=asc&classroom_id=" + props.filter.classroomId + "&year_month=" + props.filter.yearMonth, `temperatures/monthly?year_month=${date.format("YYYY-MM-DD")}&classroom_id=${props.filter.classroomId}&group_id=${props.filter.groupId}`]); // 表示するクラスを整理する

        /** @type {Object<number, any[]>} */

        const classMonthlyTemperature = data[1].items;
        students.value = classMonthlyTemperature;
        visibleClassroom.value = [];
        classMonthData.value = {};
        dailyEvents.value = {};

        if (props.filter.classroomId === 0) {
          // 全てのクラスを選択したとき
          classrooms.value.forEach(v => {
            if (classMonthlyTemperature[v.id]) {
              // 所属する生徒が存在する時
              visibleClassroom.value.push(v);
              classMonthData.value[v.id] = structuredClone(monthData.value);
              dailyEvents.value[v.id] = {};
            }
          });
        } else {
          // 個別のクラスを選択したとき
          const classroom = classrooms.value.find(v => v.id === parseInt(props.filter.classroomId));
          visibleClassroom.value.push(classroom);
          classMonthData.value[classroom.id] = structuredClone(monthData.value);
          dailyEvents.value[classroom.id] = {};
        }

        data[0].items.forEach(v => {
          // 月をまたぐ際は今月の範囲内に限定する
          const from = date.isSame(v.from, "month") ? moment(v.from) : date.clone().startOf("month");
          const to = date.isSame(v.to, "month") ? moment(v.to) : date.clone().endOf("month");

          while (from.isSameOrBefore(to, "day")) {
            v.classroom.forEach(w => {
              if (dailyEvents.value[w.classroom_id]) {
                const key = from.format("YYYY-MM-DD");

                if (dailyEvents.value[w.classroom_id][key]) {
                  dailyEvents.value[w.classroom_id][key].push(v);
                } else {
                  dailyEvents.value[w.classroom_id][key] = [v];
                }

                classMonthData.value[w.classroom_id][from.date() - 1].isSchoolDayOff = Boolean(v.is_school_day_off);
              }
            });
            from.add(1, "day");
          }
        });
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    watch(props.filter, () => setData());
    const showModal = ref(false);
    const selectedStudent = ref({});

    const handleCommentEdit = v => {
      showModal.value = true;
      selectedStudent.value = v;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const getNoticeLevel = t => {
      if (t?.temperature) {
        if (t.temperature >= 37.5) {
          return "warning";
        } else if (t.temperature >= 37.0) {
          return "notice";
        }
      }

      return "";
    }; // created


    setData();
    return {
      __sfc: true,
      props,
      classrooms,
      isLoading,
      absentAlertStore,
      monthData,
      days,
      visibleClassroom,
      classMonthData,
      dailyEvents,
      students,
      setData,
      showModal,
      selectedStudent,
      handleCommentEdit,
      closeModal,
      getNoticeLevel,
      MonthlyTableHead,
      MonthlySchoolDay,
      MonthDailyEvent,
      MonthlyComment
    };
  }

};