import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import moment from "moment";
export default {
  store,
  name: "EventCSVUpload",
  components: {
    MessageModal,
    LoadingMessage
  },

  data() {
    return {
      csv: [],
      notice: [],
      error: [],
      tableError: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false,
      notifyRegistered: true
    };
  },

  computed: { ...mapState(["classrooms", "grades", "me", "school"]),
    // 何件のデータが登録されたか
    entryCount: function () {
      return this.csv.length - 1;
    }
  },

  created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },

  methods: {
    downloadTemplateCSV() {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["開始年月日", "開始時間", "終了年月日", "終了時間", "タイトル", "授業日・休日", "対象クラス・学年"]])], {
        type: "text/csv;charset=utf-8"
      }), "event_template.csv");
    },

    setAttachment(e) {
      const csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.tableError = [];
      this.notice = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      const reader = new FileReader();

      reader.onload = e => {
        const codes = new Uint8Array(e.target.result);
        const encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        const from = encoding === "UTF8" ? "UTF8" : "SJIS";
        let csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        const csv = Papa.parse(csvString);

        if (csv.data.length - 1 === 0) {
          this.error.push("年間予定が1件も入力されていません。入力してからアップロードしてください");
        }

        this.csv = this.csvDataCleaning(csv); //同名ファイルの再アップロードを検知するためにリセット

        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },

    csvDataCleaning(csv) {
      function isValidFormatDate(cell) {
        return cell.match(/^20\d{2}\/\d{1,2}\/\d{1,2}$/);
      }

      function isValidFiscalYear(cell, elevating) {
        //年次処理に設定されている実行日(の3ヶ月前)を取得
        let elevateFiscalDate = moment().subtract(3, "months");

        if (elevating && elevating.date) {
          elevateFiscalDate = moment(elevating.date, "YYYY-MM-DD").subtract(3, "months");
        }

        const nowFiscalDate = moment().subtract(3, "months"); //操作日の年度を判定する。
        //年次処理が設定されているが、終わっていない場合は前年度とする。
        //条件は、年次処理が登録されていて、操作日の年度と年次処理日の年度が同じで、操作日より年次処日の方が未来であれば前年度とする。

        let fiscalYear = nowFiscalDate.year();

        if (elevateFiscalDate && nowFiscalDate.year() === elevateFiscalDate.year() && nowFiscalDate.date() < elevateFiscalDate.date()) {
          //年次処理(elevating.date)に入力された年度を取得
          fiscalYear = fiscalYear - 1;
        } //入力されたイベントの年度を取得


        const cellFiscalYear = moment(cell, "YYYY-MM").subtract(3, "months").year();
        return cellFiscalYear === fiscalYear;
      }

      function isValidFormatTime(cell) {
        return cell.match(/^\d{1,2}:\d{2}$/);
      }

      function zenkakuToHankaku(str) {
        //全角→半角
        str = str.replace(/：/g, /:/);
        str = str.replace(/／/g, /\//);
        str = str.replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
        return str;
      } //エラーテーブルを初期化


      csv.data.forEach(v => {
        const row = [];
        v.forEach(() => row.push(false));
        this.tableError.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(v => v.message);
      }

      const data = [];
      const heading = ["開始年月日", "開始時間", "終了年月日", "終了時間", "タイトル", "授業日・休日", "対象クラス・学年"];
      csv.data.forEach((row, i) => {
        //余分な列を落とす
        row = row.slice(0, heading.length);

        if (i === 0) {
          if (row.join() !== heading.join(",")) {
            this.error.push("見出し行が「開始年月日」「開始時間」「終了年月日」「終了時間」「タイトル」「授業日・休日」「対象クラス・学年」と異なります");
            this.tableError[i] = this.tableError[i].map(() => true);
          }

          return data.push(row);
        }

        if (!row.find(v => v)) {
          //行のすべての値が空だったら無視する
          return;
        }

        if (row.length < heading.length) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            this.error.push(i + 1 + "行目の値が7列ではありません");
            this.tableError[i] = this.tableError[i].map(() => true);
            return data.push(row);
          } else {
            return;
          }
        } //未入力や不正な値はnullのままで管理する


        let startDay = null;
        let startTime = null;
        let isStartTimeValid = true;
        let endDay = null;
        row = row.map((cell, j) => {
          cell = Encoding.toHankakuCase(cell).trim();
          let valid = true;

          if (j === 0 || j === 2) {
            //許容している形式から"2022/02/22"の形式に統一させる
            //全角→半角
            cell = zenkakuToHankaku(cell); //2022年2月22日→2022/02/22

            if (cell.match(/^20\d{2}年\d{1,2}月\d{1,2}日$/)) {
              cell = moment(cell, "YYYY年MM月DD日").format("YYYY/MM/DD");
            }

            if (cell.match(/^20\d{2}\/\d{1,2}\/\d{1,2}$/)) {
              if (j === 0) {
                startDay = moment(cell, "YYYY/MM/DD").format("YYYY/MM/DD");
              } else if (j === 2) {
                endDay = moment(cell, "YYYY/MM/DD").format("YYYY/MM/DD");
              }
            }
          } //開始年月日


          if (j === 0) {
            if (!cell || cell === "") {
              this.error.push(i + 1 + "行目の開始年月日を入力してください");
              valid = false;
            } else if (!isValidFormatDate(cell)) {
              this.error.push(i + 1 + "行目の開始年月日の形式が異なっています。2022/2/22や2022年2月22日などと入力してください");
              valid = false;
            } else if (!isValidFiscalYear(cell, this.school.elevating)) {
              this.error.push(i + 1 + "行目の開始年月日が、今年度外の日になっています。年次処理がまだ行われいない場合は、年次処理前の年度の登録のみ行えます");
              valid = false;
            }
          } //開始時間


          if (j === 1) {
            //全角→半角
            cell = zenkakuToHankaku(cell);

            if (cell) {
              if (cell.match(/^\d{1,2}:\d{2}$/)) {
                startTime = cell;
              } else {
                isStartTimeValid = false;
              }
            } //開始時間と終了時間が未入力であれば終日の予定とするので、ここではエラーにしない


            if (cell && cell !== "" && !isValidFormatTime(cell)) {
              this.error.push(i + 1 + "行目の開始時間の形式が異なっています。13:05などと入力してください");
              valid = false;
            }
          } //終了年月日


          if (j === 2) {
            if (!cell || cell === "") {
              this.error.push(i + 1 + "行目の終了年月日を入力してください");
              valid = false;
            } else if (!isValidFormatDate(cell)) {
              this.error.push(i + 1 + "行目の終了年月日の形式が異なっています。2022/2/22や2022年2月22日などと入力してください");
              valid = false;
            } else if (startDay && startDay > endDay) {
              this.error.push(i + 1 + "行目の終了年月日を修正してください。終了年月日を開始年月日よりも過去に設定することはできません");
              valid = false;
            } else if (!isValidFiscalYear(cell, this.school.elevating)) {
              this.error.push(i + 1 + "行目の終了年月日が、今年度外の日になっています。年次処理がまだ行われいない場合は、年次処理前の年度の登録のみ行えます");
              valid = false;
            }
          } //終了時間


          if (j === 3) {
            cell = zenkakuToHankaku(cell);

            if (startTime === null && cell === "") {
              //開始時間と終了時間が入力されていなければ、終日の予定とするのでエラーではない
              return;
            } else if (cell === "" && startTime !== null) {
              this.error.push(i + 1 + "行目の終了時間が入力されていません。開始時間と終了時間は、両者を入力もしくは両者を空白にしてください");
              valid = false; //開始時間が入力されていないのに、終了時間が入力されている場合
            } else if (cell !== "" && startTime === null && isStartTimeValid === true) {
              this.error.push(i + 1 + "行目の終了時間が入力されています。開始時間と終了時間は、両者を入力もしくは両者を空白にしてください");
              valid = false;
            } else if (!isValidFormatTime(cell)) {
              this.error.push(i + 1 + "行目の終了時間の形式が異なっています。13:05などと入力してください");
              valid = false; //開始時間よりも終了時間の方が過去、かつ開始日と終了日が同じ場合
              //開始日>終了日のパターンはj === 2の時の判定で網羅されているため、ここでは対象としない
              //特に、開始日>終了日かつ開始時間>終了時間の時は、開始日もしくは終了日の入力のみが間違っている場合があるので、対象としない
            } else if (startDay && startTime && endDay && startDay === endDay && Number(cell.replace(":", "")) < Number(startTime.replace(":", ""))) {
              this.error.push(i + 1 + "行目の終了時間を修正してください。終了時間を開始時間よりも過去に設定することはできません");
              valid = false;
            }
          } //タイトル


          if (j === 4) {
            if (!cell) {
              this.error.push(i + 1 + "行目のタイトルを入力してください。");
              valid = false;
            } else if (cell.length > 200) {
              this.error.push(i + 1 + "行目のタイトルを200文字以内にしてください");
              valid = false;
            }
          } //授業日・休日


          if (j === 5) {
            if (!cell) {
              this.error.push(i + 1 + "行目の授業日・休日を入力してください");
              valid = false;
            } else if (cell !== "授業日" && cell !== "休日") {
              this.error.push(i + 1 + "行目の授業日・休日の形式が異なっています。「授業日」「休日」のどちらかを入力してください");
              valid = false;
            }
          } //対象クラス・学年


          if (j === 6) {
            if (!cell) {
              this.error.push(i + 1 + "行目の対象クラス・学年を入力してください。");
              valid = false;
            } else {
              const targets = cell.split(/,|，/).map(v => v.trim());
              let isTargetValid = true; //カンマで分割した対象クラス・学年の中に、「全校」「学年名」「クラス名」のどれにも当てはまらないものがあれば、isTargetValidをfalseにする。

              targets.forEach(target => {
                if (target !== "全校" && !this.grades.find(g => g.name === target) && !this.classrooms.find(c => c.name === target)) {
                  isTargetValid = false;
                }
              });

              if (!isTargetValid) {
                this.error.push(i + 1 + "行目の対象クラス・学年を修正してください。「全校」「学年名」「クラス名」のいずれかをカンマ区切りで入力してください");
                valid = false;
              }
            }
          }

          if (!valid) this.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return data;
    },

    saveData() {
      this.isLoading = true; //ヘッダー行の除去

      this.csv.shift();
      const isSchoolDayOffMap = {
        授業日: 0,
        休日: 1
      };
      const events = this.csv.map(v => {
        let isAllDay = 0;

        if (!v[1] && !v[3]) {
          isAllDay = 1;
          v[1] = v[3] = "0:00";
        } else if (v[1] === "0:00" && v[3] === "0:00") {
          isAllDay = 1;
        }

        let event_classroom_ids = [];
        const target_names = v[6].replace(/\s+/g, "").split(/,|，/).map(v => v.trim());
        target_names.map(target_name => {
          if (target_name === "全校") {
            event_classroom_ids = this.classrooms.map(v => v.id);
          } else if (this.grades.find(g => g.name === target_name)) {
            const input_grade = this.grades.find(g => g.name === target_name);
            event_classroom_ids = event_classroom_ids.concat(input_grade.classroom.map(v => v.id));
          } else if (this.classrooms.find(c => c.name === target_name)) {
            const input_classroom = this.classrooms.find(c => c.name === target_name);
            event_classroom_ids = event_classroom_ids.concat([input_classroom.id]);
          }
        }); //重複したクラスIDを削除しArrayに戻す

        event_classroom_ids = new Set(event_classroom_ids);
        event_classroom_ids = Array.from(event_classroom_ids);
        return {
          title: v[4],
          from: v[0] + " " + v[1],
          to: v[2] + " " + v[3],
          is_school_day_off: isSchoolDayOffMap[v[5]],
          is_all_day: isAllDay,
          event_classroom: event_classroom_ids
        };
      });
      createData("writer/bulk_write_event", {
        events: events
      }).then(() => {
        this.$store.dispatch("commitModalMessage", {
          message: "年間予定情報一括登録が完了しました",
          title: "登録完了"
        });
        this.showModal = true;
        this.isLoading = false;
      }).catch(error => {
        if (error.response.status === 409) {
          this.$store.dispatch("commitModalMessage", {
            message: error.response.data.errors,
            title: "登録失敗"
          });
        } else {
          this.$store.dispatch("commitModalMessage", {
            message: "年間予定情報一括登録に失敗しました",
            title: "登録失敗"
          });
        }

        this.showModal = true;
        this.isLoading = false;
      });
    },

    completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "events"
      });
    },

    open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },

    close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }

  }
};