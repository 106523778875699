import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  name: "StudentSelector",
  components: {
    RowItem,
    LoadingMessage
  },
  props: {
    sendTargets: {
      type: Object,
      default: () => {}
    },
    confirmMode: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoading: {
      status: false,
      message: "クラスを取得中"
    },
    filter: {
      keyword: "",
      attendance: [],
      classrooms: [],
      groups: []
    },
    classrooms: [],
    groups: [],
    showOnlySelected: false,
    showGroup: false,
    currentTab: "selectClassrooms",
    students: [],
    filteredStudents: [],
    selectedStudents: [],
    participants: [],
    filteredParticipants: [],
    selectedParticipants: []
  }),
  computed: { ...mapState(["grades", "studentAddress"]),
    hiddenStudentExists: function () {
      return this.selectedStudents.length > this.filteredStudents.length;
    },
    classroomsCount: function () {
      let classroomsCount = 0;
      this.grades.forEach(v => classroomsCount += v.classroom.length);
      return classroomsCount;
    },
    selectAllClassrooms: {
      get: function () {
        return this.filter.classrooms.length === this.classroomsCount;
      },
      set: function () {
        return this.filter.classrooms.length === this.classroomsCount;
      }
    },
    selectAllGroups: {
      get: function () {
        return this.filter.groups.length === this.groups.length;
      },
      set: function () {
        return this.filter.groups.length === this.groups.length;
      }
    },
    selectAllTargets: {
      get: function () {
        return this.selectedStudents.length === this.filteredStudents.length && this.selectedParticipants.length === this.filteredParticipants.length;
      },
      set: function () {
        return this.selectedStudents.length === this.filteredStudents.length && this.selectedParticipants.length === this.filteredParticipants.length;
      }
    },
    classroomGroups: function () {
      return this.groups.filter(v => v.type === "student" && v.class_id);
    },
    studentGroups: function () {
      return this.groups.filter(v => v.type === "student" && !v.class_id);
    },
    participantGroups: function () {
      return this.groups.filter(v => v.type === "participant");
    },
    indeterminateClassrooms: function () {
      const indeterminateClassrooms = [];
      this.classrooms.forEach(v => {
        if (this.selectedStudents.length > 0 && !this.filter.classrooms.includes(v.id) && v.student.find(v => {
          return this.selectedStudents.includes(v.id);
        })) {
          indeterminateClassrooms.push(v.id);
        }
      });
      return indeterminateClassrooms;
    }
  },
  watch: {
    confirmMode: {
      handler: function (val) {
        if (val) {
          this.currentTab = "selectStudents";
          this.addMembers().then(() => {
            this.showOnlySelected = true;
          });
        }
      },
      immediate: true
    },
    "filter.classrooms": {
      handler: function (val, oldVal) {
        if (oldVal && val.length < oldVal.length) {
          const removedClassroomId = oldVal.find(v => !val.includes(v));
          const c = this.classrooms.find(v => v.id === removedClassroomId);

          if (c) {
            this.selectedStudents = this.selectedStudents.filter(v => {
              return !c.student.find(v2 => v2.id === v);
            });
          }
        }
      }
    }
  },

  mounted() {
    //メッセージの編集の場合に送信先の選択状態を復帰
    if (this.$props.sendTargets.students) {
      this.selectedStudents = this.$props.sendTargets.students;
    }

    if (this.$props.sendTargets.participants) {
      this.selectedParticipants = this.$props.sendTargets.participants;
    }

    this.isLoading.status = true;
    this.isLoading.message = "クラスとグループを取得中";
    getData(["reader/get_classrooms_for_messenger", "reader/get_groups_for_messenger"]).then(data => {
      this.classrooms = data[0].items;

      if (this.selectedStudents.length > 0) {
        this.classrooms.forEach(v => {
          if (v.student.length > 0 && !v.student.find(v2 => {
            return !this.selectedStudents.includes(v2.id);
          })) {
            this.filter.classrooms.push(v.id);
          }
        });
      }

      this.groups = data[1].items;
    }).catch(error => {
      console.log(error);
    }).then(() => {
      this.isLoading.status = false;
      this.isLoading.message = "";
    });
  },

  methods: {
    isIndeterminateClassroom(classroomId) {
      return this.indeterminateClassrooms.includes(classroomId);
    },

    addMembers() {
      this.isLoading.status = true;
      this.isLoading.message = this.studentAddress + "を取得中";
      let studentQuery = "";
      let participantQuery = "";

      if (this.currentTab === "selectClassrooms" && this.filter.classrooms.length > 0) {
        this.filter.classrooms.forEach(v => {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
        this.indeterminateClassrooms.forEach(v => {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
      } else if (this.currentTab === "selectGroups" && this.filter.groups.length > 0) {
        this.filter.groups.forEach(v => {
          studentQuery += (studentQuery === "" ? "?" : "&") + "groups[]=" + v;
        });
        participantQuery += this.filter.groups.reduce((prev, current) => prev + "&groups[]=" + current, "");
      }

      return getData(["reader/get_student_list_for_messenger" + studentQuery, "participants?with=group " + participantQuery]).then(data => {
        this.students = this.filteredStudents = data[0].items;
        this.participants = this.filteredParticipants = data[1].items;

        if (studentQuery || participantQuery) {
          if (studentQuery) {
            const selectedStudents = this.selectedStudents.slice();
            this.filteredStudents.forEach(v => {
              if (!selectedStudents.includes(v.id) && !this.indeterminateClassrooms.includes(v.classroom.id)) {
                selectedStudents.push(v.id);
              }
            });
            this.selectedStudents = selectedStudents;
          }

          if (participantQuery) {
            const selectedParticipants = this.selectedParticipants.slice();
            this.filteredParticipants.forEach(v => {
              if (!selectedParticipants.includes(v.id)) {
                selectedParticipants.push(v.id);
              }
            });
            this.selectedParticipants = selectedParticipants;
          }
        }
      }).catch(error => {
        console.log(error);
      }).then(() => {
        this.isLoading.status = false;
      });
    },

    changeAll(e, model) {
      if (model === "classroom") {
        const selected = [];

        if (e.target.checked) {
          this.grades.forEach(g => {
            g.classroom.forEach(v => {
              selected.push(v.id);
            });
          });
        }

        this.filter.classrooms = selected;
      }

      if (model === "group") {
        if (e.target.checked) {
          this.filter.groups = this.groups.map(v => v.id);
        } else {
          this.filter.groups = [];
        }
      }

      if (model === "target") {
        if (e.target.checked) {
          this.selectedStudents = this.filteredStudents.map(v => v.id);
          this.selectedParticipants = this.filteredParticipants.map(v => v.id);
        } else {
          this.selectedStudents = [];
          this.selectedParticipants = [];
        }
      }
    },

    filterStudents() {
      let students = this.students;
      let participants = this.participants;
      let keyword = this.filter.keyword;
      const attendance = this.filter.attendance;

      if (keyword) {
        keyword = keyword.trim().split(/[\x20\u3000]+/);
        students = students.filter(v => v.name.indexOf(keyword) > -1);
        participants = participants.filter(v => v.name.indexOf(keyword) > -1);
      }

      if (attendance.length > 0) {
        students = students.filter(v => {
          return attendance.find(v2 => {
            return v.attendance[0] && v.attendance[0].type.indexOf(v2) > -1;
          });
        });
      }

      this.filteredStudents = students;
      this.filteredParticipants = participants;
    },

    showSelectedMembers() {
      this.addMembers();
      this.currentTab = "selectStudents";
    },

    saveTargets() {
      this.filter.classrooms = [];
      this.filter.groups = [];
      this.$emit("update:selectedClassrooms", this.filter.classrooms);
      this.$emit("update:sendTargets", {
        students: this.selectedStudents,
        participants: this.selectedParticipants
      });
      this.$emit("close");
    },

    divition(index, denominator) {
      return index % denominator === 0;
    }

  }
};